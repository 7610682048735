import React, { useState, useRef } from "react";
import { FaTimes, FaUpload, FaImage } from "react-icons/fa";
import { uploadImage } from "../api/images";

// Maximum file size in bytes (15MB to be safe with 16MB server limit)
const MAX_FILE_SIZE = 15 * 1024 * 1024;

const ImageUploadModal = ({
  isOpen,
  onClose,
  onImageUpload,
  darkMode = false,
  paragraphIndex = null,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [caption, setCaption] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [fileSize, setFileSize] = useState(0);
  const fileInputRef = useRef(null);

  if (!isOpen) return null;

  const validateFileSize = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      setError(
        `Image is too large (${(file.size / (1024 * 1024)).toFixed(
          2
        )}MB). Maximum size is 15MB.`
      );
      return false;
    }
    setError(null);
    return true;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileSize(file.size);
      if (validateFileSize(file)) {
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setSelectedFile(null);
        setPreviewUrl(null);
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setFileSize(file.size);
      if (validateFileSize(file)) {
        setSelectedFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setSelectedFile(null);
        setPreviewUrl(null);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (previewUrl) {
      setIsUploading(true);
      setError(null);

      try {
        // Upload image to server
        const response = await uploadImage(previewUrl, caption);

        // Pass the image ID and URL from the server response
        onImageUpload({
          imageId: response.image_id,
          imageUrl: response.image_url,
          caption,
          paragraphIndex,
        });

        setIsUploading(false);
        onClose();
      } catch (error) {
        console.error("Error uploading image:", error);
        const errorMessage =
          error.response?.status === 413
            ? "Image is too large for the server to process. Please use a smaller image (under 15MB)."
            : "Failed to upload image. Please try again.";
        setError(errorMessage);
        setIsUploading(false);
      }
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    setCaption("");
    onClose();
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-70">
      <div
        className={`w-full max-w-lg rounded-lg shadow-xl overflow-hidden ${
          darkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"
        }`}
      >
        <div
          className={`px-6 py-4 border-b ${
            darkMode ? "border-gray-700" : "border-gray-200"
          } flex justify-between items-center`}
        >
          <h3 className="text-xl font-semibold">Add Image to Story</h3>
          <button
            onClick={handleCancel}
            className={`rounded-full p-1 ${
              darkMode ? "hover:bg-gray-700" : "hover:bg-gray-200"
            }`}
          >
            <FaTimes />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          <div
            className={`mb-4 border-2 border-dashed rounded-lg ${
              darkMode
                ? "border-gray-600 bg-gray-700"
                : "border-gray-300 bg-gray-50"
            } p-4 text-center cursor-pointer`}
            onClick={() => fileInputRef.current.click()}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              accept="image/*"
              className="hidden"
            />

            {previewUrl ? (
              <div className="relative">
                <img
                  src={previewUrl}
                  alt="Preview"
                  className="max-h-64 mx-auto rounded"
                />
                <div className="mt-2 text-xs text-center">
                  <span
                    className={
                      fileSize > MAX_FILE_SIZE * 0.8
                        ? "text-amber-500"
                        : "text-gray-500"
                    }
                  >
                    File size: {formatFileSize(fileSize)}
                  </span>
                </div>
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedFile(null);
                    setPreviewUrl(null);
                    setFileSize(0);
                  }}
                  className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                >
                  <FaTimes size={14} />
                </button>
              </div>
            ) : (
              <div className="py-8">
                <FaImage
                  className={`mx-auto text-4xl mb-2 ${
                    darkMode ? "text-gray-500" : "text-gray-400"
                  }`}
                />
                <p className="text-sm">
                  Drag and drop an image here, or click to select
                </p>
                <p
                  className={`text-xs mt-1 ${
                    darkMode ? "text-gray-400" : "text-gray-500"
                  }`}
                >
                  Supports: JPG, PNG, GIF (max 5MB)
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="caption"
              className={`block mb-2 text-sm font-medium ${
                darkMode ? "text-gray-300" : "text-gray-700"
              }`}
            >
              Image Caption (optional)
            </label>
            <input
              type="text"
              id="caption"
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
              className={`w-full px-3 py-2 border rounded-lg ${
                darkMode
                  ? "bg-gray-700 border-gray-600 text-white"
                  : "bg-white border-gray-300 text-gray-900"
              } focus:outline-none focus:ring-2 ${
                darkMode ? "focus:ring-blue-500" : "focus:ring-amber-500"
              }`}
              placeholder="Add a caption for your image"
            />
          </div>

          {error && (
            <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded-lg">
              {error}
            </div>
          )}

          <div className="flex justify-end space-x-3 mt-6">
            <button
              type="button"
              onClick={handleCancel}
              className={`px-4 py-2 rounded-lg ${
                darkMode
                  ? "bg-gray-700 hover:bg-gray-600 text-white"
                  : "bg-gray-200 hover:bg-gray-300 text-gray-800"
              }`}
              disabled={isUploading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 rounded-lg flex items-center ${
                !previewUrl
                  ? "bg-gray-400 cursor-not-allowed"
                  : darkMode
                  ? "bg-blue-600 hover:bg-blue-700 text-white"
                  : "bg-amber-500 hover:bg-amber-600 text-white"
              }`}
              disabled={!previewUrl || isUploading}
            >
              {isUploading ? (
                <>
                  <span className="animate-spin mr-2">⟳</span>
                  Uploading...
                </>
              ) : (
                <>
                  <FaUpload className="mr-2" />
                  Add to Story
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ImageUploadModal;
