import React, { useState, useRef, useEffect } from "react";
import {
  FaComments,
  FaHistory,
  FaChevronUp,
  FaChevronDown,
} from "react-icons/fa";

const AIThoughtProcessViewer = ({ currentThought, thoughtHistory }) => {
  const [showFullHistory, setShowFullHistory] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const scrollContainerRef = useRef(null);

  // Auto-scroll to bottom when new thoughts are added
  useEffect(() => {
    if (scrollContainerRef.current && showFullHistory) {
      scrollContainerRef.current.scrollTop =
        scrollContainerRef.current.scrollHeight;
    }
  }, [thoughtHistory, showFullHistory]);

  const toggleHistoryView = () => {
    setShowFullHistory(!showFullHistory);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  if (!currentThought && (!thoughtHistory || thoughtHistory.length === 0)) {
    return null;
  }

  return (
    <div className="mt-2 bg-blue-50 border border-blue-200 text-blue-700 px-3 py-2 rounded-md text-sm">
      <div className="flex justify-between items-center mb-1">
        <div className="flex items-center">
          <FaComments className="mr-2 text-blue-500" />
          <strong className="font-bold">
            {showFullHistory ? "AI Reasoning History" : "Current AI Reasoning"}
          </strong>
        </div>
        <div className="flex items-center space-x-2">
          {thoughtHistory && thoughtHistory.length > 1 && (
            <button
              onClick={toggleHistoryView}
              className="text-blue-600 hover:text-blue-800 transition-colors duration-200"
              title={
                showFullHistory
                  ? "Show only current thought"
                  : "Show full history"
              }
            >
              <FaHistory className="w-4 h-4" />
            </button>
          )}
        </div>
      </div>

      <div
        ref={scrollContainerRef}
        className={`overflow-y-auto transition-all duration-300 ${
          expanded ? "max-h-96" : "max-h-32"
        }`}
        style={{ scrollBehavior: "smooth" }}
      >
        {showFullHistory ? (
          thoughtHistory.map((thought, index) => (
            <div
              key={index}
              className={`mb-2 ${
                index === thoughtHistory.length - 1 ? "font-medium" : ""
              }`}
            >
              <div className="flex items-start">
                <div className="ml-2 w-full">
                  <div className="flex items-center">
                    <span className="text-xs text-blue-500 mr-2">
                      Step {index + 1}:
                    </span>
                  </div>
                  <p className="whitespace-pre-line">{thought}</p>
                  {index < thoughtHistory.length - 1 && (
                    <div className="border-b border-blue-200 my-2"></div>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="whitespace-pre-line">{currentThought}</p>
        )}
      </div>
    </div>
  );
};

export default AIThoughtProcessViewer;
