import React, { useState } from "react";
import { FiEdit2, FiCheck, FiX, FiRefreshCw, FiCopy } from "react-icons/fi";
import { getRandomPremise } from "../utils/premiseProvider";

const StoryDetails = ({
  premise,
  genre,
  onUpdate,
  isFinal,
  darkMode = false,
  isNsfw = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedPremise, setEditedPremise] = useState(premise);
  const [editedGenre, setEditedGenre] = useState(genre);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSubmit = (e) => {
    // e.preventDefault();
    onUpdate(editedPremise, editedGenre);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedPremise(premise);
    setEditedGenre(genre);
    setIsEditing(false);
  };

  const handleRefresh = () => {
    const newPremiseData = getRandomPremise(isNsfw);
    onUpdate(newPremiseData.premise, newPremiseData.genre);
  };

  const handleEditClick = () => {
    setEditedPremise(premise);
    setEditedGenre(genre);
    setIsEditing(true);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(premise);
  };

  if (isEditing) {
    return (
      <form className="space-y-4">
        <div>
          <label
            htmlFor="premise"
            className={`block text-sm font-medium mb-1 ${
              darkMode ? "text-gray-300" : "text-gray-700"
            }`}
          >
            Story Premise
          </label>
          <textarea
            id="premise"
            value={editedPremise}
            onChange={(e) => setEditedPremise(e.target.value)}
            className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-amber-500 focus:border-transparent ${
              darkMode
                ? "bg-gray-700 border-gray-600 text-gray-100"
                : "bg-white border-gray-300 text-gray-900"
            }`}
            rows={3}
          />
        </div>
        <div>
          <label
            htmlFor="genre"
            className={`block text-sm font-medium mb-1 ${
              darkMode ? "text-gray-300" : "text-gray-700"
            }`}
          >
            Genre (optional)
          </label>
          <input
            type="text"
            id="genre"
            value={editedGenre}
            onChange={(e) => setEditedGenre(e.target.value)}
            className={`w-full p-2 border rounded-lg focus:ring-2 focus:ring-amber-500 focus:border-transparent ${
              darkMode
                ? "bg-gray-700 border-gray-600 text-gray-100"
                : "bg-white border-gray-300 text-gray-900"
            }`}
          />
        </div>
        <div className="flex justify-end space-x-2">
          <button
            type="button"
            onClick={handleCancel}
            className={`p-2 rounded-full ${
              darkMode
                ? "bg-gray-700 text-gray-300 hover:bg-gray-600"
                : "bg-gray-100 text-gray-600 hover:bg-gray-200"
            }`}
          >
            <FiX size={18} />
          </button>
          <button
            onClick={handleSubmit}
            className={`p-2 rounded-full ${
              darkMode
                ? "bg-amber-600 text-white hover:bg-amber-700"
                : "bg-amber-500 text-white hover:bg-amber-600"
            }`}
          >
            <FiCheck size={18} />
          </button>
        </div>
      </form>
    );
  }

  return (
    <div
      className={`rounded-lg p-2 ${
        darkMode ? "bg-gray-800" : "bg-white"
      } shadow-lg`}
    >
      <div className="flex flex-col gap-2">
        <div className="w-full relative">
          <p
            onClick={() => setIsExpanded(!isExpanded)}
            className={`text-base sm:text-lg ${
              darkMode ? "text-gray-200" : "text-gray-700"
            } ${!isExpanded ? "truncate" : ""} cursor-pointer pr-8`}
          >
            {premise}
          </p>
          {isExpanded && (
            <button
              onClick={copyToClipboard}
              className={`absolute top-0 right-0 p-1 rounded-full hover:bg-gray-100 ${
                darkMode ? "text-gray-300 hover:bg-gray-700" : "text-gray-600"
              }`}
              title="Copy to clipboard"
            >
              <FiCopy size={16} />
            </button>
          )}
        </div>

        <div className="flex items-center justify-between">
          <div>
            {genre && (
              <span className="inline-flex flex-shrink-0 px-2 py-1 text-xs font-semibold rounded-full bg-blue-100 text-blue-800">
                {genre}
              </span>
            )}
          </div>
          <div className="flex items-center gap-2 flex-shrink-0">
            {!isFinal && (
              <>
                <button
                  onClick={handleRefresh}
                  className="flex items-center justify-center w-8 h-8 sm:w-auto sm:h-auto sm:px-3 sm:py-1 text-sm rounded-lg bg-gray-100 hover:bg-gray-200 text-gray-700 transition-colors"
                  title="New Premise"
                >
                  <FiRefreshCw className="w-4 h-4" />
                  <span className="hidden sm:inline sm:ml-1">New Premise</span>
                </button>
                <button
                  onClick={handleEditClick}
                  className="flex items-center justify-center w-8 h-8 sm:w-auto sm:h-auto sm:px-3 sm:py-1 text-sm rounded-lg bg-gray-100 hover:bg-gray-200 text-gray-700 transition-colors"
                  title="Edit"
                >
                  <FiEdit2 className="w-4 h-4" />
                  <span className="hidden sm:inline sm:ml-1">Edit</span>
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoryDetails;
