import React, { useState, useEffect } from "react";
import { FaTimes, FaTrash } from "react-icons/fa";
import { useIsMobile } from "../utils/useIsMobile";
import { getImage } from "../api/images";

const StoryImage = ({
  index,
  imageId,
  imageUrl,
  caption = "",
  onRemove,
  darkMode = false,
  showImageButtons = false,
}) => {
  const isMobile = useIsMobile();
  const [isSelected, setIsSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Determine the image source to use
  const [imageSrc, setImageSrc] = useState(imageUrl);

  useEffect(() => {
    const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";

    // If we have an imageId, construct the full URL for the image
    if (imageId) {
      setImageSrc(`${API_URL}${imageUrl}`);
    } else {
      setImageSrc(imageUrl);
    }
  }, [imageId, imageUrl]);

  const handleImageClick = () => {
    setIsSelected(!isSelected);
  };

  return (
    <div className="relative my-6">
      {(!isMobile || showImageButtons) && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onRemove();
          }}
          className="absolute right-0 top-0 transform -translate-y-1/2 w-6 h-6 bg-red-500 text-white rounded-full flex items-center justify-center sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-200 hover:bg-red-600 z-10"
          aria-label="Remove image"
        >
          <FaTimes size={14} />
        </button>
      )}

      <div
        className={`flex flex-col items-center group ${
          darkMode ? "text-gray-200" : "text-gray-800"
        } ${isSelected ? "ring-2 ring-blue-500 rounded-lg" : ""}`}
      >
        <div
          className="w-full max-w-3xl mx-auto overflow-hidden rounded-lg shadow-lg cursor-pointer"
          onClick={handleImageClick}
        >
          {loading ? (
            <div className="h-64 w-full flex items-center justify-center">
              <div className="animate-spin h-10 w-10 border-4 border-blue-500 rounded-full border-t-transparent"></div>
            </div>
          ) : error ? (
            <div className="h-64 w-full flex items-center justify-center bg-red-100 text-red-500">
              Failed to load image
            </div>
          ) : (
            <img
              src={imageSrc}
              alt={caption || "Story illustration"}
              className="w-full h-auto object-contain"
              loading="lazy"
            />
          )}
        </div>

        {caption && (
          <p
            className={`text-sm italic mt-2 text-center ${
              darkMode ? "text-gray-400" : "text-gray-600"
            }`}
          >
            {caption}
          </p>
        )}

        {/* Delete Button - only visible when image is selected */}
        {isSelected && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
            className={`mt-3 px-4 py-2 flex items-center gap-2 rounded-md ${
              darkMode
                ? "bg-red-700 hover:bg-red-800 text-white"
                : "bg-red-500 hover:bg-red-600 text-white"
            } transition-colors duration-200`}
            aria-label="Delete image"
          >
            <FaTrash size={14} />
            <span>Delete Image</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default StoryImage;
