import axios from "axios";

const API_VER = "api/v1/";
const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";

/**
 * Upload an image to the server
 * @param {string} imageData - Base64 encoded image data
 * @param {string} caption - Optional image caption
 * @returns {Promise<Object>} - Response with image URL and ID
 */
export const uploadImage = async (imageData, caption = "") => {
  try {
    // Set a longer timeout for large file uploads (60 seconds)
    const response = await axios.post(
      `${API_URL}/${API_VER}images/upload`,
      {
        image: imageData,
        caption,
        contentType: "image/jpeg", // Default content type
      },
      {
        timeout: 60000,
        headers: {
          "Content-Type": "application/json",
        },
        maxContentLength: 16 * 1024 * 1024, // 16MB
        maxBodyLength: 16 * 1024 * 1024, // 16MB
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error uploading image:", error);

    // Enhance error message based on the type of error
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 413) {
        throw new Error(
          "Image is too large. The server rejected the upload. Please use a smaller image."
        );
      } else {
        throw new Error(
          `Server error: ${error.response.status} ${error.response.statusText}`
        );
      }
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error(
        "No response from server. Please check your connection and try again."
      );
    } else {
      // Something happened in setting up the request that triggered an Error
      throw error;
    }
  }
};

/**
 * Get an image by its ID
 * @param {string} imageId - The ID of the image to retrieve
 * @returns {Promise<Object>} - Response with image data
 */
export const getImage = async (imageId) => {
  try {
    const response = await axios.get(`${API_URL}/${API_VER}images/${imageId}`, {
      headers: {
        Accept: "application/json", // Request JSON instead of binary data
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting image:", error);
    throw error;
  }
};

/**
 * Delete an image by its ID
 * @param {string} imageId - The ID of the image to delete
 * @returns {Promise<Object>} - Response with success status
 */
export const deleteImage = async (imageId) => {
  try {
    const response = await axios.delete(
      `${API_URL}/${API_VER}images/${imageId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting image:", error);
    throw error;
  }
};
