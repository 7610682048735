import React from "react";
import { FaTimes } from "react-icons/fa";

const SettingsPopup = ({
  preferences,
  updatePreferences,
  mode,
  countProp,
  onClose,
  generationMode,
}) => {
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-30 z-50 flex items-center justify-center backdrop-blur-sm transition-opacity duration-300"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-xl shadow-2xl w-full max-w-md m-4 transform transition-all duration-300 ease-out animate-fadeIn flex flex-col max-h-[60vh]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center p-6 border-b border-gray-100">
          <h3 className="text-xl font-semibold text-gray-800">
            Story Settings
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 transition-colors p-1 rounded-full hover:bg-gray-100"
          >
            <FaTimes size={20} />
          </button>
        </div>

        <div className="space-y-6 p-6 overflow-y-auto">
          {/* Paragraph Count - Only show if not in rewrite mode */}
          {mode !== "rewrite" && (
            <div className="space-y-3">
              <div className="flex justify-between items-center">
                <label className="text-sm font-medium text-gray-700">
                  Number of Paragraphs
                </label>
                <span className="text-sm font-medium text-yellow-600 bg-yellow-50 px-2 py-1 rounded-md">
                  {preferences.paragraphCount}
                </span>
              </div>
              <input
                type="range"
                min="1"
                max={mode === "continue_scene" ? countProp : 10}
                value={preferences.paragraphCount}
                onChange={(e) =>
                  updatePreferences({
                    paragraphCount: parseInt(e.target.value),
                  })
                }
                className="w-full h-2 bg-yellow-200 rounded-lg appearance-none cursor-pointer accent-yellow-500"
              />
              <div className="flex justify-between text-xs text-gray-500">
                <span>1</span>
                <span>{mode === "continue_scene" ? countProp : 10}</span>
              </div>
            </div>
          )}

          {/* NSFW Toggle */}
          <div className="flex items-center justify-between py-2 border-t border-gray-100">
            <div>
              <label className="text-sm font-medium text-gray-700 block">
                NSFW Content
              </label>
              <p className="text-xs text-gray-500 mt-1">
                Allow mature content in generated text
              </p>
            </div>
            <button
              onClick={() => updatePreferences({ isNSFW: !preferences.isNSFW })}
              className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none ${
                preferences.isNSFW ? "bg-yellow-500" : "bg-gray-300"
              }`}
            >
              <span
                className={`inline-block h-4 w-4 transform rounded-full bg-white shadow-md transition-transform ${
                  preferences.isNSFW ? "translate-x-6" : "translate-x-1"
                }`}
              />
            </button>
          </div>

          {/* Dialogue Rich Toggle - Only for new_paragraphs or insert modes */}
          {(mode === "new_paragraphs" || mode === "insert") && (
            <div className="flex items-center justify-between py-2 border-t border-gray-100">
              <div>
                <label className="text-sm font-medium text-gray-700 block">
                  Dialogue Rich
                </label>
                <p className="text-xs text-gray-500 mt-1">
                  Generate more character dialogue
                </p>
              </div>
              <button
                onClick={() =>
                  updatePreferences({ dialogueRich: !preferences.dialogueRich })
                }
                className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none ${
                  preferences.dialogueRich ? "bg-yellow-500" : "bg-gray-300"
                }`}
              >
                <span
                  className={`inline-block h-4 w-4 transform rounded-full bg-white shadow-md transition-transform ${
                    preferences.dialogueRich ? "translate-x-6" : "translate-x-1"
                  }`}
                />
              </button>
            </div>
          )}

          {/* Story Advancement Percentage - Only for new_paragraphs mode with specific generation mode */}
          {mode === "new_paragraphs" && generationMode !== "open_ended" && (
            <div className="space-y-3 py-2 border-t border-gray-100">
              <div className="flex justify-between items-center">
                <label className="text-sm font-medium text-gray-700">
                  Story Advancement
                </label>
                <span className="text-sm font-medium text-yellow-600 bg-yellow-50 px-2 py-1 rounded-md">
                  {preferences.percentageToAdvance}%
                </span>
              </div>
              <input
                type="range"
                min="5"
                max="75"
                step="5"
                value={preferences.percentageToAdvance}
                onChange={(e) =>
                  updatePreferences({
                    percentageToAdvance: parseInt(e.target.value),
                  })
                }
                className="w-full h-2 bg-yellow-200 rounded-lg appearance-none cursor-pointer accent-yellow-500"
              />
              <div className="flex justify-between text-xs text-gray-500">
                <span>5%</span>
                <span>75%</span>
              </div>
            </div>
          )}

          {/* Use Reasoning Model Toggle */}
          <div className="flex items-center justify-between py-2 border-t border-gray-100">
            <div>
              <label className="text-sm font-medium text-gray-700 block">
                Use Reasoning Model
              </label>
              <p className="text-xs text-gray-500 mt-1">
                Use a more thoughtful AI model
              </p>
            </div>
            <button
              onClick={() =>
                updatePreferences({
                  useReasoningModel: !preferences.useReasoningModel,
                  showReasoning: !preferences.useReasoningModel
                    ? true
                    : preferences.showReasoning,
                })
              }
              className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none ${
                preferences.useReasoningModel ? "bg-yellow-500" : "bg-gray-300"
              }`}
            >
              <span
                className={`inline-block h-4 w-4 transform rounded-full bg-white shadow-md transition-transform ${
                  preferences.useReasoningModel
                    ? "translate-x-6"
                    : "translate-x-1"
                }`}
              />
            </button>
          </div>
        </div>

        <div className="p-6 border-t border-gray-100 flex justify-end mt-auto">
          <button
            onClick={onClose}
            className="px-5 py-2 bg-yellow-500 text-white font-medium rounded-lg hover:bg-yellow-600 transition-colors duration-200 shadow-sm hover:shadow focus:outline-none focus:ring-2 focus:ring-yellow-300"
          >
            Apply Settings
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsPopup;
