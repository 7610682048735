import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import EditingParagraph from "./EditingParagraph";
import RewrittenParagraph from "./RewrittenParagraph";
import ParagraphMenu from "./ParagraphMenu";
import ContentGenerator from "./ContentGenerator";
import { useStory } from "../context/StoryContext";
import { FaCheck, FaTimes, FaTrash, FaImage } from "react-icons/fa";
import SectionBreak from "./SectionBreak";
import { useIsMobile } from "../utils/useIsMobile";
import {
  handleOutsideClick,
  saveCursorPosition,
  restoreCursorPosition,
  convertSentencesToParagraph,
  splitIntoSentences,
} from "../utils/paragraphUtils";
import { diffChars, diffWordsWithSpace } from "diff";
import { getContextForMode } from "../utils/contextUtils";
import { useHistory } from "react-router-dom";

const Paragraph = ({
  content,
  index,
  isActive,
  setActiveParagraph,
  isCollapsed,
  showSectionBreakButtons,
  showSummarySentencesButtons,
  onDelete,
  darkMode,
  isNsfw = false,
  isClickable = true,
  onDraftStateChange,
  onAddImage,
}) => {
  const isMobile = useIsMobile();
  const { state, dispatch } = useStory();
  const [editedContent, setEditedContent] = useState(content);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const paragraphRef = useRef(null);
  const originalContent = useRef(content);
  const [rewrittenParagraphs, setRewrittenParagraphs] = useState(null);
  const [mode, setMode] = useState("edit");
  const menuRef = useRef(null);
  const [replacementContent, setReplacementContent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [savedInstruction, setSavedInstruction] = useState("");
  const history = useHistory();

  // Memoize the split sentences to prevent re-splitting on every render
  const memoizedSentences = useMemo(() => {
    let sentences = splitIntoSentences(content);
    // console.log(sentences);
    return sentences;
  }, [content]); // Only re-split when content changes

  useEffect(() => {
    if (isActive) {
      // document.addEventListener("mousedown", (e) =>
      //   handleOutsideClick(e, paragraphRef, menuRef, setActiveParagraph)
      // );
    } else {
      // document.removeEventListener("mousedown", (e) =>
      //   handleOutsideClick(e, paragraphRef, menuRef, setActiveParagraph)
      // );
    }

    return () => {
      // document.removeEventListener("mousedown", (e) =>
      //   handleOutsideClick(e, paragraphRef, menuRef, setActiveParagraph)
      // );
    };
  }, [isActive, setActiveParagraph]);

  useEffect(() => {
    if (!isActive) {
      setEditedContent(content);
      originalContent.current = content;
    }
  }, [content, isActive]);

  useEffect(() => {
    if (replacementContent) {
      console.log(replacementContent);
    }
  }, [replacementContent]);

  useEffect(() => {
    if (isDeleting) {
    }
  }, [isDeleting]);

  useEffect(() => {
    // console.log({ isClickable });
  }, [isClickable]);

  const handleInput = (event) => {
    const cursorPosition = saveCursorPosition(event.target);
    const newContent = event.target.innerText;
    const original = originalContent.current;

    // Use diffWordsWithSpace to get the diffs at the word level, including spaces
    const wordDiffs = diffWordsWithSpace(original, newContent);

    let formattedContent = "";
    let originalIndex = 0; // Index to keep track of position in the original text

    wordDiffs.forEach((wordPart) => {
      if (wordPart.added) {
        // Wrap added words in a span
        formattedContent += `<span class="text-blue-600">${escapeHtml(
          wordPart.value
        )}</span>`;
      } else if (wordPart.removed) {
        // Ignore removed words
        originalIndex += wordPart.value.length;
      } else {
        // For unchanged or modified words, check for in-word differences
        const originalSegment = original.substr(
          originalIndex,
          wordPart.value.length
        );
        if (wordPart.value === originalSegment) {
          // Words are identical, append as is
          formattedContent += escapeHtml(wordPart.value);
        } else {
          // Words differ, perform character-level diff within the word
          formattedContent += highlightInWordDifferences(
            originalSegment,
            wordPart.value
          );
        }
        originalIndex += wordPart.value.length;
      }
    });

    console.log(formattedContent);

    setEditedContent(formattedContent);

    setTimeout(() => {
      restoreCursorPosition(paragraphRef.current, cursorPosition);
    }, 0);
  };

  // Function to highlight differences within words
  const highlightInWordDifferences = (originalWord, newWord) => {
    const charDiffs = diffChars(originalWord, newWord);
    let result = "";

    charDiffs.forEach((charPart) => {
      const text = escapeHtml(charPart.value);
      if (charPart.added) {
        result += `<span class="text-blue-600">${text}</span>`;
      } else if (charPart.removed) {
        // Ignore removed characters
      } else {
        result += text;
      }
    });

    return result;
  };

  // Utility function to escape HTML characters to prevent XSS attacks
  const escapeHtml = (unsafe) => {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  };

  const handleEdit = useCallback(async (event) => {
    if (event) {
      event.preventDefault();
    }
    setIsEditing(true);
  }, []);

  const handleEditComplete = (editedContent) => {
    setEditedContent(editedContent);
    setIsEditing(false);
    // dispatch({
    //   type: "UPDATE_PARAGRAPH",
    //   payload: { index, content: editedContent },
    // });
    // originalContent.current = editedContent;
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      await handleEdit();
    } else if (event.key === "Tab") {
      event.preventDefault();
      setEditedContent(originalContent.current);
      dispatch({
        type: "UPDATE_PARAGRAPH",
        payload: { index, content: originalContent.current },
      });
    }
  };

  const handleClick = () => {
    if (!isActive && isClickable) {
      setActiveParagraph(index);
    }
  };

  const handleBlur = () => {
    if (!isProcessing) {
      // setEditedContent(originalContent.current);
    }
  };

  const handleModeChange = useCallback((newMode) => {
    setMode(newMode);
    if (newMode === "delete") {
      handleDelete();
    }
  }, []);

  const handleContentFinalize = async (generatedContent) => {
    onDraftStateChange(false);
    if (mode === "rewrite") {
      setIsProcessing(true);
      try {
        const newParagraphs = generatedContent.map((paragraph) =>
          convertSentencesToParagraph(paragraph.sentences)
        );
        // Replace the current paragraph with the first new paragraph
        const updatedContent = newParagraphs[0];
        setEditedContent(updatedContent);
        dispatch({
          type: "UPDATE_PARAGRAPH",
          payload: { index, content: updatedContent },
        });
        originalContent.current = updatedContent;

        // Insert the remaining paragraphs as new ones
        for (let i = 1; i < newParagraphs.length; i++) {
          dispatch({
            type: "INSERT_PARAGRAPH",
            payload: { index: index + i, content: newParagraphs[i] },
          });
        }
      } catch (error) {
        console.error("Error rewriting paragraph:", error);
      } finally {
        setIsProcessing(false);
        setRewrittenParagraphs(null);
      }
    } else if (mode === "insert") {
      generatedContent.forEach((paragraph, i) => {
        dispatch({
          type: "INSERT_PARAGRAPH",
          payload: { index: index + i + 1, content: paragraph },
        });
      });
      setMode("edit");
    } else if (mode === "edit") {
      const newParagraphs = generatedContent.map((paragraph) =>
        convertSentencesToParagraph(paragraph.sentences)
      );
      // Replace the current paragraph with the first new paragraph
      const updatedContent = newParagraphs[0];
      setEditedContent(updatedContent);
      dispatch({
        type: "UPDATE_PARAGRAPH",
        payload: { index, content: updatedContent },
      });
      originalContent.current = updatedContent;
      setRewrittenParagraphs(null);
    }
  };

  const handleContentReject = () => {
    setRewrittenParagraphs(null);
  };

  const handleDelete = () => {
    setIsDeleting(true);
    setTimeout(() => {
      onDelete(index);
      if (isActive) {
        setActiveParagraph(null);
      }
    }, 300); // This should match the duration of the CSS transition
  };

  const handleInsert = () => {
    setMode("insert");
  };

  const getContext = (mode) => {
    return getContextForMode(mode, state, index);
  };

  const handleRewriteGeneratedContent = (content) => {
    //Spread operator to create a new array, so that the rewrittenParagraphs state is updated and re-renders
    setIsGenerating(true);
    setRewrittenParagraphs([...content]);
  };

  const handleFinishedGeneration = () => {
    setIsGenerating(false);
  };

  const handleExpansion = (mode) => {
    // Navigate to compact version with current state and paragraph context
    history.push("/ai-story-generator/compact", {
      fromFullVersion: true,
      story_title: state.title,
      mode,
      activeParagraph: index,
      paragraphContext: {
        index,
        content,
        isNsfw,
      },
    });
  };

  // Check if the next item is a section break
  const nextItemIsSectionBreak =
    state.paragraphs[index + 1]?.type === "section_break";

  useEffect(() => {
    if (paragraphRef.current) {
      paragraphRef.current.__react_internal_instance$ = {
        return: {
          stateNode: {
            handleModeChange,
          },
        },
      };
    }
  }, [handleModeChange]);

  const handleSaveInstruction = useCallback((instruction) => {
    setSavedInstruction(instruction);
  }, []);

  // Add function to handle sentence click
  const handleSentenceClick = (sentenceIndex) => {
    if (!showSummarySentencesButtons) return;

    dispatch({
      type: "TOGGLE_PICKED_SENTENCE",
      payload: { paragraphIndex: index, sentenceIndex },
    });
  };

  // Add function to handle sentence deletion
  const handleSentenceDelete = (sentenceIndex) => {
    // Log the action
    console.log(
      `Delete sentence at paragraph ${index}, sentence ${sentenceIndex}`
    );

    // First remove the sentence from picked sentences if it's picked
    if (state.pickedSentences?.[index]?.includes(sentenceIndex)) {
      dispatch({
        type: "TOGGLE_PICKED_SENTENCE",
        payload: { paragraphIndex: index, sentenceIndex },
      });
    }

    // Get the current sentences to check if this is the last one
    const currentSentences = splitIntoSentences(state.paragraphs[index]);
    const isLastSentence = currentSentences.length === 1;

    // Then delete the sentence from the paragraph
    dispatch({
      type: "DELETE_SENTENCE",
      payload: { paragraphIndex: index, sentenceIndex },
    });

    // If this was the last sentence, the paragraph will be deleted
    // so we should clear the active paragraph
    if (isLastSentence && isActive) {
      setActiveParagraph(null);
    } else {
      // Update the original content reference to match the new content
      originalContent.current = state.paragraphs[index];
    }
  };

  // Function to render sentences with click handlers and highlighting picked sentences
  const renderSentences = () => {
    // console.log(memoizedSentences);
    const isSelected = (sentenceIndex) =>
      state.pickedSentences?.[index]?.includes(sentenceIndex) || false;

    return memoizedSentences.map((sentence, sentenceIndex) => (
      <React.Fragment key={sentenceIndex}>
        <span
          onClick={() => handleSentenceClick(sentenceIndex)}
          className={`cursor-pointer transition-colors duration-200 ${
            isSelected(sentenceIndex)
              ? darkMode
                ? "bg-amber-700 hover:bg-amber-600"
                : "bg-amber-200 hover:bg-amber-300"
              : showSummarySentencesButtons
              ? darkMode
                ? "hover:bg-gray-700"
                : "hover:bg-gray-100"
              : ""
          }`}
        >
          {sentence}
        </span>
        {isSelected(sentenceIndex) && showSummarySentencesButtons && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleSentenceDelete(sentenceIndex);
            }}
            className="inline-flex items-center justify-center bg-red-500 text-white px-2 py-1 rounded-md shadow-lg hover:bg-red-600 transition-colors duration-200 ml-2 align-middle"
            title="Delete sentence"
          >
            <FaTrash size={14} className="mr-1" />
          </button>
        )}
        {sentenceIndex < memoizedSentences.length - 1 && " "}
      </React.Fragment>
    ));
  };

  if (isEditing) {
    return (
      <EditingParagraph
        content={editedContent}
        originalContent={originalContent.current}
        onEditComplete={handleEditComplete}
      />
    );
  }

  if (rewrittenParagraphs && rewrittenParagraphs.length > 0) {
    return (
      <div>
        {rewrittenParagraphs.map((p, index) => (
          <RewrittenParagraph key={index} sentences={p.sentences} />
        ))}
        {!isGenerating && (
          <div className="flex flex-col sm:flex-row gap-2 mt-4">
            <button
              onClick={() => handleContentFinalize(rewrittenParagraphs)}
              className={`flex-1 px-4 py-2 bg-green-500 text-white text-sm font-medium rounded-md hover:bg-green-600 transition-colors duration-200 flex items-center justify-center ${
                darkMode ? "hover:bg-green-700" : "hover:bg-green-600"
              }`}
            >
              <FaCheck className="mr-2" /> Accept
            </button>
            <button
              onClick={handleContentReject}
              className={`flex-1 px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-md hover:bg-red-600 transition-colors duration-200 flex items-center justify-center ${
                darkMode ? "hover:bg-red-700" : "hover:bg-red-600"
              }`}
            >
              <FaTimes className="mr-2" /> Reject
            </button>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <div
        ref={paragraphRef}
        data-paragraph-index={index}
        className={`relative px-4 sm:px-6 md:px-8 transition-opacity duration-300 ease-in-out ${
          isCollapsed ? "h-0 overflow-hidden opacity-0" : "opacity-100"
        } ${!isClickable ? "pointer-events-none opacity-50" : ""}`}
      >
        {!showSummarySentencesButtons ? (
          <div
            className={`cursor-pointer transition-all duration-300 ease-in-out ${
              isActive ? "ring-2 ring-yellow-400" : ""
            }`}
          >
            <p
              ref={paragraphRef}
              className={`text-base sm:text-lg leading-relaxed ${
                darkMode ? "text-gray-200" : "text-gray-800"
              } p-2 font-serif transition-all duration-300 ease-in-out ${
                isActive && mode === "edit" && !isProcessing
                  ? darkMode
                    ? "bg-gray-800"
                    : "bg-white"
                  : ""
              }`}
              style={{
                textShadow: "0 1px 1px rgba(0,0,0,0.1)",
                textIndent: "1em",
                wordSpacing: "0.05em",
                letterSpacing: "0.01em",
              }}
              onClick={handleClick}
              onBlur={handleBlur}
              onInput={handleInput}
              onKeyDown={handleKeyDown}
              contentEditable={isActive && mode === "edit" && !isProcessing}
              suppressContentEditableWarning={true}
              dangerouslySetInnerHTML={{ __html: editedContent }}
            />
          </div>
        ) : (
          <div
            className={`text-base sm:text-lg leading-relaxed ${
              darkMode ? "text-gray-200" : "text-gray-800"
            } p-2 font-serif`}
          >
            {renderSentences()}
          </div>
        )}
        {isProcessing && (
          <div
            className={`absolute inset-0 flex items-center justify-center ${
              darkMode ? "bg-gray-800" : "bg-white"
            } bg-opacity-75 rounded-lg`}
          >
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-yellow-500"></div>
          </div>
        )}
        {isActive && (
          <div ref={menuRef} className="mt-2">
            <ParagraphMenu
              mode={mode}
              onModeChange={handleModeChange}
              onDelete={handleDelete}
              onInsert={handleInsert}
              darkMode={darkMode}
            />
            {(mode === "rewrite" || mode === "insert") && (
              <ContentGenerator
                onFinalize={handleContentFinalize}
                mode={mode}
                context={getContext(mode)}
                onGeneratedContent={handleRewriteGeneratedContent}
                onFinished={handleFinishedGeneration}
                onExpanded={(mode) => handleExpansion(mode)}
                title={
                  mode === "rewrite" ? "Rewrite paragraph" : "Insert paragraphs"
                }
                savedInstruction={savedInstruction}
                onSaveInstruction={handleSaveInstruction}
                isNsfw={isNsfw}
                onDraftStateChange={onDraftStateChange}
              />
            )}
          </div>
        )}

        {!nextItemIsSectionBreak && (
          <>
            <div className="md:hidden">
              {showSectionBreakButtons && (
                <>
                  <SectionBreak
                    index={index}
                    isAfterParagraph={true}
                    onAddImage={onAddImage}
                  />
                </>
              )}
            </div>
            <div className="hidden md:block">
              <SectionBreak
                index={index}
                isAfterParagraph={true}
                onAddImage={onAddImage}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Paragraph;
