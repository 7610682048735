import React, { createContext, useContext, useReducer } from "react";
import { splitIntoSentences } from "../utils/paragraphUtils";

const StoryContext = createContext();

const remapPickedSentences = (
  pickedSentences,
  oldIndex,
  newIndex,
  isDelete = false
) => {
  const newPickedSentences = {};

  // Check if pickedSentences is undefined or null
  if (!pickedSentences) {
    return newPickedSentences;
  }

  Object.entries(pickedSentences).forEach(([paragraphIdx, sentences]) => {
    const idx = parseInt(paragraphIdx);

    if (isDelete) {
      // For deletion: skip the deleted index and shift down indices that come after
      if (idx < oldIndex) {
        newPickedSentences[idx] = sentences;
      } else if (idx > oldIndex) {
        newPickedSentences[idx - 1] = sentences;
      }
    } else {
      // For insertion: shift up indices that come after insertion point
      if (idx < newIndex) {
        newPickedSentences[idx] = sentences;
      } else {
        newPickedSentences[idx + 1] = sentences;
      }
    }
  });

  return newPickedSentences;
};

const storyReducer = (state, action) => {
  switch (action.type) {
    case "SET_PREMISE":
      return { ...state, premise: action.payload };
    case "SET_GENRE":
      return { ...state, genre: action.payload };
    case "SET_NSFW":
      return { ...state, isNsfw: action.payload };
    case "SET_PARAGRAPHS":
      return { ...state, paragraphs: action.payload };
    case "SET_PICKED_SENTENCES":
      return { ...state, pickedSentences: action.payload };
    case "ADD_PARAGRAPHS":
      return { ...state, paragraphs: [...state.paragraphs, ...action.payload] };
    case "INSERT_PARAGRAPH":
      return {
        ...state,
        paragraphs: [
          ...state.paragraphs.slice(0, action.payload.index),
          action.payload.content,
          ...state.paragraphs.slice(action.payload.index),
        ],
        pickedSentences: remapPickedSentences(
          state.pickedSentences,
          action.payload.index,
          action.payload.index
        ),
      };
    case "DELETE_PARAGRAPH":
      // Also update images that might reference this paragraph
      const updatedImages = state.images
        .map((image) => {
          if (image.paragraphIndex === action.payload) {
            return null; // Mark for removal
          } else if (image.paragraphIndex > action.payload) {
            // Adjust index for images that come after the deleted paragraph
            return { ...image, paragraphIndex: image.paragraphIndex - 1 };
          }
          return image;
        })
        .filter(Boolean); // Remove null entries

      return {
        ...state,
        paragraphs: state.paragraphs.filter((_, idx) => idx !== action.payload),
        pickedSentences: remapPickedSentences(
          state.pickedSentences,
          action.payload,
          null,
          true
        ),
        images: updatedImages,
      };
    case "UPDATE_PARAGRAPH":
      return {
        ...state,
        paragraphs: state.paragraphs.map((paragraph, idx) =>
          idx === action.payload.index ? action.payload.content : paragraph
        ),
      };
    case "SET_TITLE":
      return { ...state, title: action.payload };
    case "RESET_STORY":
      console.log("Resetting story state");
      return {
        premise: "",
        genre: "",
        paragraphs: [],
        images: [],
        title: "",
        generationMode: "open_ended",
        pickedSentences: {},
        collapsedSections: [],
        areAllSectionsCollapsed: false,
        storyProgress: 0,
        overall_summary: "",
        isNsfw: false,
        tokenUsage: {
          completion_tokens: 0,
          prompt_tokens: 0,
          total_tokens: 0,
        },
      };
    case "ADD_SECTION_BREAK":
      return {
        ...state,
        paragraphs: [
          ...state.paragraphs.slice(0, action.payload.index),
          { type: "section_break", summary: action.payload.summary },
          ...state.paragraphs.slice(action.payload.index),
        ],
      };
    case "ADD_IMAGE":
      return {
        ...state,
        images: [
          ...state.images,
          {
            id: Date.now().toString(), // Unique ID for the image
            paragraphIndex: action.payload.paragraphIndex,
            imageId: action.payload.imageId, // Store the image ID from the server
            imageUrl: action.payload.imageUrl, // Store the URL to fetch the image
            caption: action.payload.caption || "",
          },
        ],
      };
    case "REMOVE_SECTION_BREAK":
      return {
        ...state,
        paragraphs: state.paragraphs.filter(
          (_, idx) => idx !== action.payload.index
        ),
      };
    case "REMOVE_IMAGE":
      return {
        ...state,
        images: state.images.filter((_, idx) => idx !== action.payload.index),
      };
    case "UPDATE_SECTION_SUMMARY":
      return {
        ...state,
        paragraphs: state.paragraphs.map((item, idx) =>
          idx === action.payload.index && item.type === "section_break"
            ? { ...item, summary: action.payload.summary }
            : item
        ),
      };
    case "UPDATE_IMAGE_CAPTION":
      return {
        ...state,
        images: state.images.map((image, idx) =>
          idx === action.payload.index
            ? { ...image, caption: action.payload.caption }
            : image
        ),
      };
    case "SET_COLLAPSED_SECTIONS":
      return { ...state, collapsedSections: action.payload };
    case "TOGGLE_SECTION_COLLAPSE":
      return {
        ...state,
        collapsedSections: state.collapsedSections.includes(action.payload)
          ? state.collapsedSections.filter((index) => index !== action.payload)
          : [...state.collapsedSections, action.payload],
      };
    case "SET_ALL_SECTIONS_COLLAPSED":
      return { ...state, areAllSectionsCollapsed: action.payload };
    case "TOGGLE_ALL_SECTIONS":
      const allSectionBreakIndices = state.paragraphs
        .map((item, index) => (item.type === "section_break" ? index : -1))
        .filter((index) => index !== -1);
      return {
        ...state,
        collapsedSections: state.areAllSectionsCollapsed
          ? []
          : allSectionBreakIndices,
        areAllSectionsCollapsed: !state.areAllSectionsCollapsed,
      };
    case "UPDATE_SECTION_BREAK_SUMMARY":
      return {
        ...state,
        paragraphs: state.paragraphs.map((item, idx) =>
          idx === action.payload.index && item.type === "section_break"
            ? { ...item, summary: action.payload.summary }
            : item
        ),
      };
    case "UPDATE_STORY_PROGRESS":
      return {
        ...state,
        storyProgress: action.payload.percentage,
      };
    case "SET_GENERATION_MODE":
      return { ...state, generationMode: action.payload };
    case "SET_OVERALL_SUMMARY":
      return { ...state, overall_summary: action.payload };
    case "TOGGLE_PICKED_SENTENCE":
      const { paragraphIndex, sentenceIndex } = action.payload;
      const pickedSentences = state.pickedSentences || {};
      const currentPicked = pickedSentences[paragraphIndex] || [];
      const newPicked = currentPicked.includes(sentenceIndex)
        ? currentPicked.filter((idx) => idx !== sentenceIndex)
        : [...currentPicked, sentenceIndex].sort((a, b) => a - b);

      return {
        ...state,
        pickedSentences: {
          ...pickedSentences,
          [paragraphIndex]: newPicked,
        },
      };
    case "DELETE_SENTENCE":
      const { paragraphIndex: pIndex, sentenceIndex: sIndex } = action.payload;
      const paragraph = state.paragraphs[pIndex];

      // Skip if paragraph doesn't exist or is a section break
      if (!paragraph || typeof paragraph !== "string") {
        return state;
      }

      // Split paragraph into sentences, remove the specified one, and join back
      const sentences = splitIntoSentences(paragraph);
      sentences.splice(sIndex, 1);
      const updatedParagraph = sentences.join(" ");

      // If the paragraph becomes empty after deletion, remove it entirely
      if (updatedParagraph.trim() === "") {
        return {
          ...state,
          paragraphs: state.paragraphs.filter((_, idx) => idx !== pIndex),
          // Also update pickedSentences to account for the removed paragraph
          pickedSentences: remapPickedSentences(
            state.pickedSentences,
            pIndex,
            null,
            true
          ),
        };
      }

      // Otherwise, update the paragraph content
      return {
        ...state,
        paragraphs: state.paragraphs.map((p, idx) =>
          idx === pIndex ? updatedParagraph : p
        ),
      };
    case "ADD_TOKENS":
      return {
        ...state,
        tokenUsage: {
          completion_tokens:
            (state.tokenUsage?.completion_tokens || 0) +
            action.payload.completion_tokens,
          prompt_tokens:
            (state.tokenUsage?.prompt_tokens || 0) +
            action.payload.prompt_tokens,
          total_tokens:
            (state.tokenUsage?.total_tokens || 0) + action.payload.total_tokens,
        },
      };
    case "SET_TOKEN_USAGE":
      return { ...state, tokenUsage: action.payload };
    case "SET_IMAGES":
      return { ...state, images: action.payload };
    default:
      return state;
  }
};

export const StoryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(storyReducer, {
    premise: "",
    genre: "",
    paragraphs: [],
    images: [], // Store images separately with paragraph references
    pickedSentences: {}, // { paragraphindex: [sentence_index_1, sentence_index_2, ...] }
    title: "",
    collapsedSections: [],
    areAllSectionsCollapsed: false,
    storyProgress: 0,
    generationMode: "open_ended",
    overall_summary: "",
    isNsfw: false,
    tokenUsage: {
      completion_tokens: 0,
      prompt_tokens: 0,
      total_tokens: 0,
    },
  });

  return (
    <StoryContext.Provider value={{ state, dispatch }}>
      {children}
    </StoryContext.Provider>
  );
};

export const useStory = () => {
  const context = useContext(StoryContext);
  if (!context) {
    throw new Error("useStory must be used within a StoryProvider");
  }
  return context;
};
