export const saveStoryToLocalStorage = (story) => {
  // Add createdAt timestamp if it doesn't exist
  const storyWithTimestamp = {
    ...story,
    createdAt: story.createdAt || new Date().toISOString(),
  };

  let stories = JSON.parse(localStorage.getItem("stories")) || [];
  const title = storyWithTimestamp.title.replace(/\s+/g, "_").toLowerCase();
  const storyToSave = { ...storyWithTimestamp, id: title };

  const storyIndex = stories.findIndex((s) => s.id === title);
  if (storyIndex !== -1) {
    stories[storyIndex] = storyToSave;
  } else {
    stories.push(storyToSave);
  }

  localStorage.setItem("stories", JSON.stringify(stories));
  // Removing console.log since it may be causing confusion when debugging
  // console.log(stories[storyIndex]);
  //   console.log("Story saved to local storage:", storyToSave);
};

export const loadLastStoryFromLocalStorage = (isNsfw) => {
  const stories = JSON.parse(localStorage.getItem("stories")) || [];
  const filteredStories = isNsfw
    ? stories.filter((s) => s.isNsfw)
    : stories.filter((s) => !s.isNsfw);

  const lastStory =
    filteredStories.length > 0
      ? filteredStories[filteredStories.length - 1]
      : null;

  console.log(
    "Loading last story from local storage. Has images:",
    lastStory?.images ? "Yes" : "No"
  );
  if (lastStory?.images) {
    console.log("Images found:", lastStory.images);
  }

  return lastStory;
};

export const loadLastSceneFromLocalStorage = () => {
  const scenes = JSON.parse(localStorage.getItem("scenes")) || [];
  return scenes.length > 0 ? scenes[scenes.length - 1] : null;
};

export const loadStoryFromTitle = (title) => {
  console.log("Loading story from title:", title);
  const stories = JSON.parse(localStorage.getItem("stories")) || [];
  const story = stories.find((s) => s.title === title);

  console.log("Story found with images:", story?.images ? "Yes" : "No");
  return story;
};

export const loadStoryFromId = (id) => {
  const stories = JSON.parse(localStorage.getItem("stories")) || [];
  const story = stories.find((s) => s.id === id);

  console.log("Loading story from ID:", id);
  console.log("Story found with images:", story?.images ? "Yes" : "No");
  if (story?.images) {
    console.log("Images found:", story.images);
  }

  return story;
};

export const loadSceneFromTitle = (title) => {
  const scenes = JSON.parse(localStorage.getItem("scenes")) || [];
  return scenes.find((s) => s.title === title);
};

export const loadAllStoriesFromLocalStorage = (isNsfw) => {
  const stories = JSON.parse(localStorage.getItem("stories")) || [];
  const filteredStories = isNsfw
    ? stories.filter((s) => s.isNsfw)
    : stories.filter((s) => !s.isNsfw);
  return filteredStories;
};

export const clearAllStoriesFromLocalStorage = () => {
  localStorage.removeItem("stories");
};

export const deleteStoryFromLocalStorage = (storyId, isNsfw) => {
  const stories = JSON.parse(localStorage.getItem("stories")) || [];
  const storyToDelete = stories.find((story) => story.id === storyId);

  // Only delete if the story exists and matches the NSFW flag
  if (storyToDelete && storyToDelete.isNsfw === isNsfw) {
    const updatedStories = stories.filter((story) => story.id !== storyId);
    localStorage.setItem("stories", JSON.stringify(updatedStories));
  }
};

export const loadAllScenesFromLocalStorage = (isNsfw) => {
  const scenes = JSON.parse(localStorage.getItem("scenes")) || [];
  const filteredScenes = isNsfw
    ? scenes.filter((s) => s.isNsfw)
    : scenes.filter((s) => !s.isNsfw);
  return filteredScenes;
};

export const clearAllScenesFromLocalStorage = () => {
  localStorage.removeItem("scenes");
};

export const deleteSceneFromLocalStorage = (sceneId) => {
  const scenes = JSON.parse(localStorage.getItem("scenes")) || [];
  const updatedScenes = scenes.filter((scene) => scene.id !== sceneId);
  localStorage.setItem("scenes", JSON.stringify(updatedScenes));
};
