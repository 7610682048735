import { useState, useEffect, useRef } from "react";

/**
 * Custom hook for implementing a delayed hover effect
 * @param {number} delay - The delay in milliseconds before showing hover content
 * @returns {Object} - Object containing state and props for the delayed hover
 */
const useDelayedHover = (delay = 500) => {
  const [isHovering, setIsHovering] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    if (isHovering) {
      timerRef.current = setTimeout(() => {
        setShowContent(true);
      }, delay);
    } else {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      setShowContent(false);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isHovering, delay]);

  const hoverProps = {
    onMouseEnter: () => setIsHovering(true),
    onMouseLeave: () => setIsHovering(false),
  };

  return { showContent, hoverProps };
};

export default useDelayedHover;
