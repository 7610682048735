import React, { useState, useEffect, useRef } from "react";
import useViewportHeight from "./utils/useViewportHeight";
import { Link, useLocation, useHistory } from "react-router-dom";
import { FaHome, FaUserCircle, FaToggleOn, FaToggleOff } from "react-icons/fa";
import { useAuth } from "./context/AuthContext";

export default function MainLayout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const isDarkMode = location.pathname === "/ai-story-generator/nsfw";
  const isNSFW = location.pathname.includes("nsfw");
  const viewportHeight = useViewportHeight();
  const { user, logout } = useAuth();
  const history = useHistory();
  const showNsfwToggle =
    location.pathname.includes("ai-story-generator") ||
    location.pathname.includes("stories");

  const handleToggleClick = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleLogout = () => {
    logout();
  };

  const handleNSFWToggle = () => {
    let basePath = "/";
    if (location.pathname.includes("ai-story-generator")) {
      basePath = "/ai-story-generator";
    } else if (location.pathname.includes("stories")) {
      basePath = "/stories";
    }

    const newPath = isNSFW ? basePath : `${basePath}/nsfw`;
    history.push(newPath);
  };

  return (
    <>
      <div
        className={`flex flex-col ${isDarkMode ? "bg-gray-900" : ""}`}
        style={{ height: `${viewportHeight}px` }}
      >
        <div
          className={`w-full ${
            isDarkMode
              ? "bg-gradient-to-r from-gray-800 to-gray-700"
              : "bg-gradient-to-r from-blue-100 to-purple-100"
          } px-4 py-2 flex items-center justify-between`}
        >
          <div className="flex items-center gap-4">
            <Link to={isNSFW ? "/nsfw" : "/"}>
              <FaHome
                className={`text-xl ${
                  isDarkMode ? "text-gray-100" : "text-gray-800"
                } hover:text-blue-500 transition-colors duration-200`}
              />
            </Link>
            <Link to={isNSFW ? "/nsfw" : "/"}>
              <span
                className={`inline-flex items-center text-sm font-semibold ${
                  isDarkMode ? "text-gray-100" : "text-gray-800"
                }`}
              >
                let me
                <span className="mx-1 text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500 transition-all duration-300 ease-in-out">
                  WRITE
                </span>
                <span>for you</span>
                {isNSFW && (
                  <span className="ml-2 text-[10px] px-1 py-0.5 bg-red-500 text-white rounded-md hidden sm:inline-block">
                    NSFW
                  </span>
                )}
              </span>
            </Link>
          </div>

          <div className="flex items-center gap-2">
            {showNsfwToggle && (
              <button
                onClick={handleNSFWToggle}
                className={`flex items-center gap-1 px-2 py-1 rounded-md text-sm
                  ${
                    isDarkMode
                      ? "text-gray-300 hover:text-gray-100"
                      : "text-gray-600 hover:text-gray-800"
                  }
                  transition-colors duration-200`}
              >
                {isNSFW ? (
                  <FaToggleOn className="text-lg text-red-500" />
                ) : (
                  <FaToggleOff className="text-lg" />
                )}
                <span className="hidden sm:inline">NSFW</span>
                {isNSFW && (
                  <span className="w-2 h-2 bg-red-500 rounded-full sm:hidden"></span>
                )}
              </button>
            )}

            {user ? (
              <div className="flex items-center gap-2">
                <span
                  className={`hidden sm:inline text-sm font-medium ${
                    isDarkMode ? "text-gray-300" : "text-gray-600"
                  } transition-colors duration-200`}
                >
                  {user.email}
                </span>
                <Link
                  to="/profile"
                  className={`
                    text-sm px-2 sm:px-4 py-1 rounded-md
                    transition-all duration-300 ease-in-out transform
                    hover:scale-105 focus:outline-none focus:ring-2
                    flex items-center gap-2 relative
                    ${
                      isDarkMode
                        ? "bg-gray-700 hover:bg-gray-600 text-gray-200 focus:ring-gray-500"
                        : "bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white focus:ring-blue-300"
                    }
                  `}
                >
                  <FaUserCircle className="text-lg" />
                  <span className="hidden sm:inline">Profile</span>
                  <span className="absolute -top-1 -right-1 w-2.5 h-2.5 bg-green-500 rounded-full sm:hidden"></span>
                </Link>
              </div>
            ) : (
              <Link
                to="/login"
                className={`
                  text-sm px-2 sm:px-4 py-1 rounded-md
                  transition-all duration-300 ease-in-out transform
                  hover:scale-105 focus:outline-none focus:ring-2
                  flex items-center gap-2 relative
                  ${
                    isDarkMode
                      ? "bg-gray-700 hover:bg-gray-600 text-gray-200 focus:ring-gray-500"
                      : "bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white focus:ring-blue-300"
                  }
                `}
              >
                <FaUserCircle className="text-lg" />
                <span className="hidden sm:inline">Login</span>
                <span className="absolute -top-1 -right-1 w-2.5 h-2.5 bg-gray-400 rounded-full sm:hidden"></span>
              </Link>
            )}
          </div>
        </div>

        <div className="flex-grow overflow-auto">
          {React.Children.map(children, (child) =>
            React.cloneElement(child, { darkMode: isDarkMode })
          )}
        </div>
      </div>
    </>
  );
}
