import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { createPaymentIntent } from "../api/apiTest";

function CheckoutPage() {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    try {
      // Create a payment intent for $10.00
      const { clientSecret } = await createPaymentIntent(10.0);

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (result.error) {
        setError(`Payment failed: ${result.error.message}`);
        setSucceeded(false);
      } else {
        setError(null);
        setSucceeded(true);
      }
    } catch (err) {
      setError(`Payment failed: ${err.message}`);
      setSucceeded(false);
    }

    setProcessing(false);
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6">Test Checkout</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </div>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        {succeeded && (
          <div className="text-green-500 mb-4">Payment successful!</div>
        )}
        <button
          type="submit"
          disabled={!stripe || processing}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 disabled:opacity-50"
        >
          {processing ? "Processing..." : "Pay $10.00"}
        </button>
      </form>
    </div>
  );
}

export default CheckoutPage;
