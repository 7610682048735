import React, { useState } from "react";
import { FiLoader } from "react-icons/fi";
import { FaChartBar, FaChevronUp, FaChevronDown } from "react-icons/fa";
import { useStory } from "../context/StoryContext";
import TokenUsageDisplay from "./TokenUsageDisplay";

const StoryTitle = ({
  title,
  isGeneratingTitle,
  onGenerateTitle,
  isCompact,
  darkMode = false,
}) => {
  const [showTokens, setShowTokens] = useState(false);
  const { state } = useStory();
  const { tokenUsage } = state;

  const toggleTokenDisplay = () => {
    setShowTokens(!showTokens);
  };

  return (
    <div className="w-full">
      <div
        className={`flex items-center justify-between w-full ${
          isCompact ? "mb-0" : "mb-2"
        }`}
      >
        <h1
          className={`font-bold truncate flex-grow transition-all duration-300 text-lg sm:text-xl ${
            darkMode ? "text-gray-100" : "text-gray-800"
          }`}
        >
          {isGeneratingTitle ? (
            <span className="flex items-center">
              <FiLoader
                className={`animate-spin mr-2 ${
                  darkMode ? "text-amber-400" : "text-amber-500"
                }`}
                size={isCompact ? 16 : 20}
              />
              <span>Generating title...</span>
            </span>
          ) : (
            title
          )}
        </h1>
        <div className="flex items-center">
          <TokenUsageDisplay
            tokenUsage={tokenUsage}
            showTokens={showTokens}
            toggleTokenDisplay={toggleTokenDisplay}
            darkMode={darkMode}
          />
        </div>
      </div>
    </div>
  );
};

export default StoryTitle;
