import React, { useState } from "react";
import Paragraph from "../components/Paragraph";
import { StoryProvider } from "../context/StoryContext";

const ParagraphView = () => {
  const [activeParagraph, setActiveParagraph] = useState(null);
  const [isDraft, setIsDraft] = useState(false);

  // Test paragraph content
  const testContent = `“What’s next—bangles? Bindi? Should’ve worn lehenga instead of chaddi, no? ” His knuckles whitened around the elevator rail as he stared at Sandy’s reflection, gaze lingering where the shorts hugged curves most office boys lacked. “Fucking disgusting,” he spat, though his throat worked like he’d tasted something sweet.`;

  // Mock story state for testing
  const initialStoryState = {
    title: "Test Story",
    paragraphs: [{ content: testContent, type: "paragraph" }],
    pickedSentences: {},
  };

  const handleDelete = (index) => {
    console.log(`Delete paragraph at index ${index}`);
  };

  return (
    <StoryProvider initialState={initialStoryState}>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-4">
          Paragraph Component Test View
        </h1>

        <div className="border p-4 rounded-lg">
          <Paragraph
            content={testContent}
            index={0}
            isActive={activeParagraph === 0}
            setActiveParagraph={setActiveParagraph}
            isCollapsed={false}
            showSectionBreakButtons={false}
            showSummarySentencesButtons={true}
            onDelete={handleDelete}
            darkMode={false}
            isNsfw={false}
            isClickable={true}
            onDraftStateChange={setIsDraft}
          />
        </div>

        <div className="mt-4">
          <h2 className="text-lg font-semibold mb-2">Test Controls:</h2>
          <div className="space-x-2">
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() =>
                setActiveParagraph(activeParagraph === 0 ? null : 0)
              }
            >
              Toggle Active State
            </button>
          </div>
        </div>
      </div>
    </StoryProvider>
  );
};

export default ParagraphView;
