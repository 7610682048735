import React, { useState, useRef, useEffect } from "react";
import ContentImprover from "./ContentImprover";
import {
  FaCheck,
  FaTimes,
  FaFastForward,
  FaUndo,
  FaTrash,
} from "react-icons/fa";
import WordByWordRenderer from "./WordByWordRenderer";
import { splitIntoSentences } from "../utils/paragraphUtils";

const DraftContentRenderer = ({
  draftContent,
  inProgress,
  onFinalize,
  onReject,
  isNsfw = false,
  mode,
}) => {
  const contentRef = useRef(null);
  const [showWordByWord, setShowWordByWord] = useState(true);
  const [isWordByWordComplete, setIsWordByWordComplete] = useState(false);
  const [selectedSentence, setSelectedSentence] = useState(null);

  const [content, setContent] = useState({
    content: draftContent,
    id: "initial",
  });

  // Function to delete the selected sentence
  const deleteSentence = (paragraphIndex, sentenceIndex) => {
    const newContent = [...content.content];
    const sentences = splitIntoSentences(newContent[paragraphIndex]);
    sentences.splice(sentenceIndex, 1);
    newContent[paragraphIndex] = sentences.join(" ");

    // Remove empty paragraphs
    const filteredContent = newContent.filter(
      (paragraph) => paragraph.trim().length > 0
    );

    setContent({
      ...content,
      content: filteredContent,
    });
    setSelectedSentence(null);
  };

  useEffect(() => {
    setContent({ content: draftContent, id: "initial" });
    // Scroll to the content when it's generated
    if (contentRef.current) {
      contentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [draftContent]);

  // Clear selected sentence when switching view modes
  useEffect(() => {
    setSelectedSentence(null);
  }, [showWordByWord]);

  const handleReject = () => {
    setIsWordByWordComplete(false);
    setShowWordByWord(true);
    onReject();
  };

  const handleFinalize = () => {
    onFinalize(content.content);
  };

  const ActionButtons = ({ mode }) => {
    return (
      <div
        className={`${
          mode === "new_paragraphs" ? "absolute" : "fixed"
        } bottom-0 left-0 right-0 bg-white border-t border-gray-200 p-3 z-50 flex justify-center`}
      >
        <div className="container max-w-6xl mx-auto flex gap-2">
          <button
            onClick={handleFinalize}
            disabled={content.id === "loading"}
            className={`flex-1 px-4 py-2 bg-green-500 text-white text-sm font-medium rounded-md hover:bg-green-600 transition-colors duration-200 flex items-center justify-center ${
              content.id === "loading" ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <FaCheck className="mr-2" /> Accept
          </button>
          <button
            onClick={handleReject}
            disabled={content.id === "loading"}
            className={`flex-1 px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-md hover:bg-red-600 transition-colors duration-200 flex items-center justify-center ${
              content.id === "loading" ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            <FaTimes className="mr-2" /> Reject
          </button>
          <button
            onClick={() => setShowWordByWord(!showWordByWord)}
            className="px-4 py-2 bg-blue-500 text-white text-sm font-medium rounded-md hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
          >
            {showWordByWord ? (
              <>
                <FaFastForward />
              </>
            ) : (
              <>
                <FaUndo />
              </>
            )}
          </button>
        </div>
      </div>
    );
  };

  const scrollToBottom = () => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  };

  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  };

  const handleImprovement = (improvement) => {
    setContent(improvement);
    scrollToTop();
  };

  const renderStaticContent = () => {
    return content.content.map((paragraph, paragraphIndex) => {
      const sentences = splitIntoSentences(paragraph);

      return (
        <p
          key={paragraphIndex}
          className="text-base sm:text-lg leading-relaxed text-gray-700 font-sans italic bg-white p-2 rounded shadow-sm relative group mb-4"
        >
          {sentences.map((sentence, sentenceIndex) => {
            const isSelected =
              selectedSentence?.paragraphIndex === paragraphIndex &&
              selectedSentence?.sentenceIndex === sentenceIndex;

            return (
              <React.Fragment key={sentenceIndex}>
                <span
                  onClick={() =>
                    setSelectedSentence({ paragraphIndex, sentenceIndex })
                  }
                  className={`cursor-pointer transition-colors duration-200 ${
                    isSelected ? "bg-red-100" : "hover:bg-gray-100"
                  } rounded px-1 py-0.5`}
                >
                  {sentence}
                </span>{" "}
                {isSelected && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteSentence(paragraphIndex, sentenceIndex);
                    }}
                    className="inline-flex items-center justify-center bg-red-500 text-white px-2 py-1 rounded-md shadow-lg hover:bg-red-600 transition-colors duration-200 ml-2 align-middle"
                    title="Delete sentence"
                  >
                    <FaTrash size={14} className="mr-1" />
                    <span className="text-sm">Delete</span>
                  </button>
                )}
              </React.Fragment>
            );
          })}
        </p>
      );
    });
  };

  const renderContent = () => {
    if (content.id === "loading") {
      return content.content.map((paragraph, index) => (
        <div key={index} className="relative">
          <p className="text-base sm:text-lg leading-relaxed text-gray-700 font-sans italic bg-white p-2 rounded shadow-sm">
            {paragraph}
          </p>
          <div className="absolute top-0 left-0 right-0 bg-white/50 flex items-center justify-center p-2 z-50">
            <div className="flex items-center gap-2">
              <div className="animate-spin h-4 w-4 border-2 border-blue-500 border-t-transparent rounded-full"></div>
              <span className="text-sm text-blue-600 font-medium">
                Improving...
              </span>
            </div>
          </div>
        </div>
      ));
    }

    return (
      Array.isArray(content.content) &&
      (showWordByWord ? (
        <WordByWordRenderer
          key={showWordByWord ? "typing" : "static"}
          paragraphs={content.content}
          typingSpeed={200}
          onWordRendered={() => {
            if (showWordByWord && contentRef.current) {
              contentRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
          }}
          onFinished={() => {
            setIsWordByWordComplete(true);
            setShowWordByWord(false);
          }}
        />
      ) : (
        renderStaticContent()
      ))
    );
  };

  return (
    <div
      className="relative"
      onClick={(e) => {
        // Clear selection when clicking outside of sentences
        if (e.target === e.currentTarget) {
          setSelectedSentence(null);
        }
      }}
    >
      <div
        ref={contentRef}
        className="mt-0 sm:mt-2 bg-gray-100 rounded-lg p-3 shadow-inner border border-gray-300"
      >
        <div className="max-h-[250px] overflow-y-auto scroll-smooth bg-white rounded p-2">
          {renderContent()}
        </div>
        {!inProgress && (
          <div>
            <ContentImprover
              content={content.content}
              onImprovement={handleImprovement}
              isNsfw={isNsfw}
            />
          </div>
        )}
      </div>
      {!inProgress && <ActionButtons mode={mode} />}
    </div>
  );
};

export default DraftContentRenderer;
