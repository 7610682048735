import React, { createContext, useState, useContext, useEffect } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  const checkTokenExpiry = async () => {
    const tokenExpiry = localStorage.getItem("tokenExpiry");
    const storedToken = localStorage.getItem("token");

    if (!storedToken) {
      logout();
      return true;
    }

    if (tokenExpiry) {
      const currentTime = new Date().getTime();
      const expiryTime = parseInt(tokenExpiry);

      if (currentTime > expiryTime) {
        console.log("Token expired, logging out");
        logout();
        return true;
      }
    }

    // Attempt to refresh the token
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/v1/auth/refresh`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        // Update token and expiry time
        localStorage.setItem("token", data.token);
        const newExpiryTime = new Date().getTime() + 5 * 24 * 60 * 60 * 1000;
        localStorage.setItem("tokenExpiry", newExpiryTime.toString());
        setToken(data.token);
      } else {
        // If refresh fails, log out
        logout();
        return true;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      // Don't logout on network errors to allow offline usage
    }

    return false;
  };

  useEffect(() => {
    // Check if user is logged in on mount
    const storedToken = localStorage.getItem("token");
    const userEmail = localStorage.getItem("userEmail");

    if (storedToken && userEmail) {
      checkTokenExpiry().then((isExpired) => {
        if (!isExpired) {
          setUser({ email: userEmail });
          setToken(storedToken);
        }
        setLoading(false);
      });
    } else {
      logout();
      setLoading(false);
    }

    // Set up periodic token check
    const tokenCheckInterval = setInterval(checkTokenExpiry, 60000); // Check every minute

    return () => clearInterval(tokenCheckInterval);
  }, []);

  const login = (newToken, email) => {
    // Set token expiry to 5 days from now
    const expiryTime = new Date().getTime() + 5 * 24 * 60 * 60 * 1000;
    localStorage.setItem("token", newToken);
    localStorage.setItem("userEmail", email);
    localStorage.setItem("tokenExpiry", expiryTime.toString());
    setUser({ email });
    setToken(newToken);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("tokenExpiry");
    setUser(null);
    setToken(null);
  };

  return (
    <AuthContext.Provider value={{ user, token, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
