import { apiCall } from "../utils/api_utils";

const API_ENDPOINTS = {
  LOGIN: "api/v1/auth/login",
  REGISTER: "api/v1/auth/register",
};

export const loginUser = async (email, password) => {
  try {
    const response = await apiCall(API_ENDPOINTS.LOGIN, "POST", {
      email,
      password,
    });
    return {
      token: response.token,
      email: response.email,
    };
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const registerUser = async (email, password) => {
  try {
    const response = await apiCall(API_ENDPOINTS.REGISTER, "POST", {
      email,
      password,
    });
    return {
      token: response.token,
      email: response.email,
    };
  } catch (error) {
    console.error("Error during registration:", error);
    throw error;
  }
};
