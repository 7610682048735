import React, { useContext, useState, useEffect, useRef } from "react";
import {
  getNewParagraphs,
  getRewriteParagraphs,
  getInsertedParagraphs,
  cancelGeneration,
} from "../api/paragraphs";
import { generateNewScene } from "../api/scenes";

import { getContextForMode } from "../utils/contextUtils";
import CustomInstructionSelector from "./CustomInstructionSelector";
import ClearableTextarea from "./ClearableTextarea";
import AIThoughtProcessViewer from "./AIThoughtProcessViewer";
import {
  FaCheck,
  FaTimes,
  FaPaperPlane,
  FaChevronUp,
  FaChevronDown,
  FaKeyboard,
  FaFastForward,
} from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { useStory } from "../context/StoryContext";
import { useScene } from "../context/SceneContext";
import Scene from "./scene/Scene";
import SceneWordByWordRenderer from "./scene/SceneWordByWordRenderer";
import DraftContentRenderer from "./DraftContentRenderer";
import SettingsPopup from "./SettingsPopup";

const DEFAULT_PREFERENCES = {
  showSettings: false,
  paragraphCount: 1,
  isNSFW: false,
  showInputBar: true,
  showSuggestionsPanel: true,
  showCustomInstructions: false,
  showWordByWord: true,
  showSceneWordByWord: true,
  percentageToAdvance: 25,
  dialogueRich: false,
  showReasoning: false,
  useReasoningModel: false,
};

const ContentGenerator = ({
  onFinalize,
  mode,
  context = {},
  title = "Generate new paragraphs",
  onGeneratedContent,
  onFinished,
  savedInstruction,
  onSaveInstruction,
  onExpanded,
  generationMode,
  isNsfw = false,
  countProp = 1,
  isExpanded = false,
  onDraftStateChange,
}) => {
  const { state, dispatch } = useStory();
  const { state: sceneState } = useScene();
  const [instruction, setInstruction] = useState(savedInstruction || "");
  const [draftContent, setDraftContent] = useState(null);
  const [sceneDraftContent, setSceneDraftContent] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [error, setError] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [preferences, setPreferences] = useState(() => {
    const savedPrefs = localStorage.getItem("storyGeneratorPreferences");
    // console.log("Saved preferences:", savedPrefs);
    let defaultPrefs = {
      ...DEFAULT_PREFERENCES,
      showInputBar: mode !== "new_paragraphs",
      isNSFW: isNsfw,
    };
    return savedPrefs ? JSON.parse(savedPrefs) : defaultPrefs;
  });
  const [aiThoughtProcess, setAiThoughtProcess] = useState(null);
  const [aiThoughtHistory, setAiThoughtHistory] = useState([]);

  const contentRef = useRef(null);

  const [isWordByWordComplete, setIsWordByWordComplete] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);

  useEffect(() => {
    setDraftContent(null);
    let count =
      mode === "rewrite" ? 1 : preferences.paragraphCount || countProp;
    setCount(count);
    setError(null);
    // setPreferences({
    //   showInputBar: mode !== "new_paragraphs",
    //   isNSFW: preferences.isNSFW,
    // });
  }, [mode, isNsfw]);

  useEffect(() => {
    // console.log("Preferences:", preferences);
  }, [preferences]);

  useEffect(() => {
    if (countProp > 1) {
      setCount(countProp);
    }
  }, [countProp]);

  useEffect(() => {
    if (onDraftStateChange) {
      if (draftContent) {
        onDraftStateChange(true);
      } else {
        onDraftStateChange(false);
      }
    }
  }, [draftContent, onDraftStateChange]);

  const onProgress = (content) => {
    setInProgress(true);
    setDraftContent(content);
    if (!preferences.useReasoningModel && !preferences.showReasoning) {
      setAiThoughtProcess(null); // Clear AI thought process only if not showing reasoning
    }
    if (mode == "rewrite") {
      onGeneratedContent(content);
    }
  };

  const onProgressReasoning = (reasoning) => {
    setAiThoughtProcess(reasoning);
    // Add to thought history if it's a new thought
    if (
      reasoning &&
      (!aiThoughtHistory.length ||
        aiThoughtHistory[aiThoughtHistory.length - 1] !== reasoning)
    ) {
      setAiThoughtHistory((prevHistory) => [...prevHistory, reasoning]);
    }
  };

  const handleTokenUsage = (usage) => {
    if (usage && typeof usage === "object") {
      dispatch({
        type: "ADD_TOKENS",
        payload: {
          completion_tokens: usage.completion_tokens || 0,
          prompt_tokens: usage.prompt_tokens || 0,
          total_tokens: usage.total_tokens || 0,
        },
      });
    }
  };

  const handleSubmit = async (e, customPercentage = null) => {
    e.preventDefault();
    e.stopPropagation();

    // Blur the active element to dismiss mobile keyboard
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    setIsFocused(false);
    setIsWordByWordComplete(false);
    onSaveInstruction(instruction);
    setIsLoading(true);
    setError(null);
    setAiThoughtProcess(null);
    setAiThoughtHistory([]); // Clear thought history for new generation
    setDraftContent(null);
    setSceneDraftContent(null);
    setIsCancelling(false);
    updatePreferences({
      showWordByWord: true,
      showSceneWordByWord: true,
    });
    try {
      if (mode === "insert") {
        if (preferences.dialogueRich) {
          generationMode = "open_ended_dialogue";
        }
        let resolved = await getInsertedParagraphs(
          instruction,
          preferences.paragraphCount,
          context,
          onProgress,
          true,
          preferences.isNSFW,
          generationMode,
          handleTokenUsage,
          preferences.useReasoningModel || preferences.showReasoning
            ? onProgressReasoning
            : null,
          preferences.useReasoningModel
        );
        if (resolved) {
          setInProgress(false);
        }
      } else if (mode === "rewrite") {
        const result = await getRewriteParagraphs(
          instruction,
          count,
          context,
          onProgress,
          true,
          true,
          handleTokenUsage,
          preferences.useReasoningModel || preferences.showReasoning
            ? onProgressReasoning
            : null,
          preferences.useReasoningModel
        );
        setDraftContent(result);
        onGeneratedContent(result);
        onFinished();
      } else if (mode === "new_paragraphs") {
        if (preferences.dialogueRich) {
          generationMode = "open_ended_dialogue";
        }
        const context = getContextForMode(
          "new_paragraphs",
          state,
          0,
          generationMode,
          customPercentage || preferences.percentageToAdvance
        );
        let resolved = await getNewParagraphs(
          instruction,
          preferences.paragraphCount,
          context,
          generationMode,
          onProgress,
          true,
          preferences.isNSFW,
          handleTokenUsage,
          preferences.useReasoningModel || preferences.showReasoning
            ? onProgressReasoning
            : null,
          preferences.useReasoningModel
        );
        if (resolved) {
          setInProgress(false);
        }
      } else if (mode === "continue_scene") {
        const context = getContextForMode("continue_scene", sceneState);
        console.log("Context for continue scene:", context);
        let resolved = await generateNewScene(
          instruction,
          preferences.isNSFW,
          count,
          context
        );
        // console.log(resolved);
        setSceneDraftContent(resolved);
      }
    } catch (error) {
      if (error.message === "Generation cancelled") {
        setAiThoughtProcess(null);
        setError("Generation was cancelled.");
      } else {
        console.error("Error generating content:", error);
        setError(error.message || "An error occurred while generating content");
      }
      setDraftContent(null);
      setSceneDraftContent(null);
    } finally {
      setIsLoading(false);
      setIsCancelling(false);
    }
  };

  const handleFinalize = (content) => {
    setDraftContent(null); //Keep this at top
    onFinalize(content);
    setInstruction("");
    setCount(countProp);
    setAiThoughtProcess(null);
    setAiThoughtHistory([]);
  };

  const handleReject = () => {
    setDraftContent(null);
    setSceneDraftContent(null);
    setAiThoughtProcess(null);
    setAiThoughtHistory([]);
    updatePreferences({
      showWordByWord: true,
      showSceneWordByWord: true,
    });
  };

  const handleCancel = async () => {
    setIsCancelling(true);
    cancelGeneration();

    // Set a timer to forcefully cancel after one minute
    const forceTimeout = setTimeout(() => {
      if (inProgress || isLoading) {
        console.log("Force cancelling generation after timeout");
        setInProgress(false);
        setIsLoading(false);
        setIsCancelling(false);
        setError("Generation was forcefully cancelled after timeout.");
        setDraftContent(null);
        setSceneDraftContent(null);
        setAiThoughtProcess(null);
      }
    }, 3000); // 3 seconds

    // Store the timeout ID in a ref so we can clear it if needed
    // This will be cleared when component unmounts or when generation completes normally
    return () => clearTimeout(forceTimeout);
  };

  const renderDraftContent = (mode) => {
    if (!draftContent) return null;

    return (
      <div className="relative">
        <DraftContentRenderer
          draftContent={draftContent}
          inProgress={inProgress}
          isNsfw={preferences.isNSFW}
          mode={mode}
          onFinalize={handleFinalize}
          onReject={handleReject}
          onDraftStateChange={onDraftStateChange}
        />
      </div>
    );
  };

  const renderSceneDraftContent = () => {
    if (!sceneDraftContent) return null;

    return (
      <div className="mt-0 sm:mt-2 bg-gray-100 rounded-lg p-3 shadow-inner border border-gray-300">
        <div
          ref={contentRef}
          className="max-h-60 overflow-y-auto scroll-smooth"
        >
          {preferences.showSceneWordByWord ? (
            <SceneWordByWordRenderer
              elements={sceneDraftContent}
              typingSpeed={50}
              dialogueTypingSpeed={100}
              dialoguePause={500}
              onComplete={() => setInProgress(false)}
              onWordRendered={() => {
                if (contentRef.current) {
                  contentRef.current.scrollTop =
                    contentRef.current.scrollHeight;
                }
              }}
            />
          ) : (
            <Scene elements={sceneDraftContent} />
          )}
        </div>
        {!inProgress && (
          <div className="flex flex-col sm:flex-row gap-2 mt-2 mb-2">
            <div className="flex-1 flex gap-2">
              <button
                onClick={handleFinalize}
                className="flex-1 px-2 py-1 bg-green-500 text-white text-xs sm:text-sm font-medium rounded-md hover:bg-green-600 transition-colors duration-200 flex items-center justify-center"
              >
                <FaCheck className="mr-1" /> Accept
              </button>
              <button
                onClick={handleReject}
                className="flex-1 px-2 py-1 bg-red-500 text-white text-xs sm:text-sm font-medium rounded-md hover:bg-red-600 transition-colors duration-200 flex items-center justify-center"
              >
                <FaTimes className="mr-1" /> Reject
              </button>
            </div>
            <button
              onClick={() =>
                setShowSceneWordByWord(!preferences.showSceneWordByWord)
              }
              className="w-full sm:w-auto px-2 py-1 bg-blue-500 text-white text-xs sm:text-sm font-medium rounded-md hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
            >
              <FaFastForward className="mr-1" />{" "}
              {preferences.showSceneWordByWord ? "Show All" : "Replay"}
            </button>
          </div>
        )}
      </div>
    );
  };

  const updatePreferences = (updates) => {
    setPreferences((prev) => {
      const newPrefs = { ...prev, ...updates };
      localStorage.setItem(
        "storyGeneratorPreferences",
        JSON.stringify(newPrefs)
      );
      return newPrefs;
    });
  };

  const handleShowSettings = () => {
    setShowSettingsPopup(true);
  };

  const handleCloseSettings = () => {
    setShowSettingsPopup(false);
  };

  const toggleInputBar = () => {
    updatePreferences({ showInputBar: !preferences.showInputBar });
  };

  const settingsButton = (
    <button
      type="button"
      onClick={handleShowSettings}
      className="p-2.5 text-yellow-600 hover:text-yellow-700 transition-all duration-200 rounded-full shadow-sm flex items-center justify-center bg-gradient-to-r from-yellow-50 to-yellow-100 hover:from-yellow-100 hover:to-yellow-200 border border-yellow-200 hover:shadow-md"
      aria-label="Settings"
      title="Story Generation Settings"
    >
      <IoMdSettings size={20} className="text-yellow-600" />
    </button>
  );

  const renderNewParagraphsButtons = () => {
    const suggestionButton = (
      <button
        onClick={toggleInputBar}
        className={`p-2 text-yellow-600 hover:text-yellow-700 transition-colors duration-200 rounded-full shadow-sm flex items-center justify-center ${
          preferences.showInputBar
            ? "bg-yellow-200 border-2 border-yellow-400"
            : "bg-white"
        }`}
      >
        <FaKeyboard className="mr-1" />
        {preferences.showInputBar ? (
          <FaChevronUp size={12} />
        ) : (
          <FaChevronDown size={12} />
        )}
      </button>
    );

    if (generationMode === "open_ended") {
      return (
        <div className="mb-2">
          <div className="flex flex-row gap-2 items-center">
            {settingsButton}
            <button
              onClick={(e) => handleSubmit(e)}
              className="flex-1 px-4 py-2 bg-amber-500 text-white text-sm font-medium rounded-md hover:bg-amber-600 transition-colors duration-200 flex items-center justify-center"
            >
              <FaPaperPlane className="mr-2" /> Continue Story
            </button>
            {suggestionButton}
          </div>
        </div>
      );
    }

    return (
      <div className="mb-2 pb-2">
        <p className="text-sm font-medium text-gray-700 mb-1">
          Progress story by:
        </p>
        <div className="flex flex-row gap-2">
          {settingsButton}
          <button
            onClick={(e) => {
              updatePreferences({ percentageToAdvance: 10 });
              handleSubmit(e, 10);
            }}
            className="flex-1 px-2 py-1 bg-amber-400 text-white text-xs sm:text-sm font-medium rounded-md hover:bg-amber-500 transition-colors duration-200 flex items-center justify-center"
          >
            +10%
          </button>
          <button
            onClick={(e) => {
              updatePreferences({ percentageToAdvance: 25 });
              handleSubmit(e, 25);
            }}
            className="flex-1 px-2 py-1 bg-amber-500 text-white text-xs sm:text-sm font-medium rounded-md hover:bg-amber-600 transition-colors duration-200 flex items-center justify-center"
          >
            +25%
          </button>
          <button
            onClick={(e) => {
              updatePreferences({ percentageToAdvance: 50 });
              handleSubmit(e, 50);
            }}
            className="flex-1 px-2 py-1 bg-amber-600 text-white text-xs sm:text-sm font-medium rounded-md hover:bg-amber-700 transition-colors duration-200 flex items-center justify-center"
          >
            +50%
          </button>
          {suggestionButton}
        </div>
      </div>
    );
  };

  const handleCustomInstructionChange = (newInstruction) => {
    setInstruction(newInstruction);
    onSaveInstruction(newInstruction);
  };

  const handleSuggestionSelect = (suggestion) => {
    setInstruction(suggestion);
    onSaveInstruction(suggestion);
  };

  const handleTextareaFocus = () => {
    setIsFocused(true);
  };

  const handleTextareaBlur = () => {
    setIsFocused(false);
  };

  const renderInputBar = () => {
    return (
      <form className="space-y-2 pb-2">
        <div className="flex items-center space-x-2">
          {mode !== "new_paragraphs" && !isFocused && settingsButton}
          {!draftContent && (
            <div className="flex-grow relative">
              {mode !== "new_paragraphs" && (
                <ClearableTextarea
                  value={instruction}
                  onChange={(value) => {
                    setInstruction(value);
                    onSaveInstruction(value);
                  }}
                  onFocus={handleTextareaFocus}
                  onMouseDown={(e) => setIsFocused(true)}
                  onBlur={handleTextareaBlur}
                  placeholder={`${title}`}
                  rows={isFocused ? 5 : 2}
                  className="w-full p-3 text-sm border border-yellow-300 rounded-md focus:ring-2 focus:ring-yellow-400 focus:border-transparent pr-12"
                />
              )}
              {mode === "new_paragraphs" && (
                <div className="flex-grow">
                  <CustomInstructionSelector
                    selectedSuggestion={instruction}
                    onSuggestionSelect={handleSuggestionSelect}
                    instruction={instruction}
                    onInstructionChange={handleCustomInstructionChange}
                    placeholder="Enter custom instruction or select a suggestion"
                    preserveInstruction={true}
                  />
                </div>
              )}
              {mode !== "new_paragraphs" && (
                <button
                  type="submit"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition-colors duration-200 flex items-center justify-center"
                  disabled={isLoading}
                  onMouseDown={(e) => handleSubmit(e)}
                  onClick={(e) => handleSubmit(e)}
                >
                  <FaPaperPlane className="w-4 h-4 sm:mr-1" />
                  <span className="hidden sm:inline">
                    {isLoading
                      ? "Processing..."
                      : mode === "rewrite"
                      ? "Rewrite"
                      : "Generate"}
                  </span>
                </button>
              )}
            </div>
          )}
          {draftContent && (
            <div className="px-3 py-2 bg-yellow-50 border border-yellow-200 rounded-md flex items-center gap-2 cursor-pointer hover:bg-yellow-100 transition-colors group">
              <span className="text-gray-600 italic line-clamp-1 group-hover:line-clamp-none">
                {instruction || "No instruction provided"}
              </span>
            </div>
          )}
        </div>
      </form>
    );
  };

  return (
    <div
      className={`bg-gradient-to-r from-yellow-50 to-yellow-100 px-2 py-2 rounded-lg shadow-md transition-all duration-300 ease-in-out relative`}
    >
      {mode === "new_paragraphs" && renderNewParagraphsButtons()}
      {(preferences.showInputBar || mode !== "new_paragraphs") &&
        renderInputBar()}

      {isLoading && (
        <div className="flex items-center justify-between py-2 px-3 bg-yellow-100 rounded-md text-sm">
          <div className="flex items-center">
            <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-yellow-500 mr-2"></div>
            <span className="text-yellow-700">Generating...</span>
          </div>
          <button
            onClick={handleCancel}
            className="text-red-600 hover:text-red-800 transition-colors duration-200 text-sm"
            disabled={isCancelling}
          >
            {isCancelling ? "Cancelling..." : "Cancel"}
          </button>
        </div>
      )}

      {(aiThoughtProcess || aiThoughtHistory.length > 0) &&
        preferences.showReasoning && (
          <AIThoughtProcessViewer
            currentThought={aiThoughtProcess}
            thoughtHistory={aiThoughtHistory}
          />
        )}

      {error && (
        <div
          className="mt-2 bg-red-100 border border-red-400 text-red-700 px-3 py-2 rounded-md text-sm"
          role="alert"
        >
          <strong className="font-bold">Error: </strong>
          <span>{error}</span>
        </div>
      )}

      {(mode === "insert" || mode === "new_paragraphs") &&
        renderDraftContent(mode)}
      {mode === "continue_scene" && renderSceneDraftContent()}

      {/* Settings Popup */}
      {showSettingsPopup && (
        <SettingsPopup
          preferences={preferences}
          updatePreferences={updatePreferences}
          mode={mode}
          countProp={countProp}
          onClose={handleCloseSettings}
          generationMode={generationMode}
        />
      )}
    </div>
  );
};

export default ContentGenerator;
