import React, { useState, useEffect } from "react";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  formContent,
  onEditForm,
  darkMode = false,
  confirmText = "Confirm",
  cancelText = "Cancel",
  isDestructive = false,
}) => {
  const [editedFormData, setEditedFormData] = useState(null);

  useEffect(() => {
    if (formContent) {
      setEditedFormData(formContent);
    }
  }, [formContent]);

  if (!isOpen) return null;

  const handleConfirm = () => {
    onConfirm();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...editedFormData, [name]: value };
    setEditedFormData(updatedData);
    if (onEditForm) {
      onEditForm(updatedData);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className={`fixed inset-0 ${
          darkMode ? "bg-black" : "bg-gray-800"
        } opacity-50`}
        onClick={onClose}
      ></div>
      <div
        className={`${
          darkMode ? "bg-gray-800" : "bg-white"
        } rounded-lg p-6 max-w-sm w-full mx-4 z-50`}
      >
        <h3
          className={`text-lg font-semibold mb-4 ${
            darkMode ? "text-gray-100" : "text-gray-900"
          }`}
        >
          {title}
        </h3>

        {message && (
          <p className={`mb-4 ${darkMode ? "text-gray-300" : "text-gray-600"}`}>
            {message}
          </p>
        )}

        {formContent && (
          <div className="mb-4 space-y-4">
            <div
              dangerouslySetInnerHTML={{ __html: formContent.html }}
              className={darkMode ? "text-gray-100" : "text-gray-900"}
            />
            {Object.entries(formContent)
              .filter(([key]) => key !== "html")
              .map(([key, value]) => (
                <input
                  key={key}
                  type="text"
                  name={key}
                  value={editedFormData?.[key] || ""}
                  onChange={handleInputChange}
                  placeholder={`Enter ${key}`}
                  className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 
                    ${
                      darkMode
                        ? "bg-gray-700 border-gray-600 text-gray-100"
                        : "bg-white border-gray-300 text-gray-900"
                    }`}
                />
              ))}
          </div>
        )}

        <div className="flex justify-end space-x-2">
          <button
            onClick={onClose}
            className={`px-4 py-2 text-sm rounded border
              ${
                darkMode
                  ? "text-gray-300 hover:bg-gray-700 border-gray-600"
                  : "text-gray-600 hover:bg-gray-100 border-gray-300"
              }`}
          >
            {cancelText}
          </button>
          <button
            onClick={handleConfirm}
            className={`px-4 py-2 text-sm text-white rounded 
              ${
                isDestructive
                  ? darkMode
                    ? "bg-red-600 hover:bg-red-700"
                    : "bg-red-500 hover:bg-red-600"
                  : darkMode
                  ? "bg-blue-600 hover:bg-blue-700"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
          >
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
