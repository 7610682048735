import { getRandomPremise } from "./premiseProvider";
import { generateTitle, getSavedStoryById } from "../api/stories";

// Constants
const STORIES_STORAGE_KEY = "stories";

/**
 * Saves a story to local storage with proper metadata
 * @param {Object} story - The story object to save
 */
export const saveStory = (story) => {
  if (!story || !story.title) {
    console.warn("Attempted to save story with no title");
    return;
  }

  // Add timestamp and create proper ID
  const storyWithMetadata = {
    ...story,
    createdAt: story.createdAt || new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    id: story.title.replace(/\s+/g, "_").toLowerCase(),
  };

  // Get current stories
  const stories = getStoriesFromStorage();

  // Update existing or add new
  const existingIndex = stories.findIndex((s) => s.id === storyWithMetadata.id);
  if (existingIndex !== -1) {
    stories[existingIndex] = storyWithMetadata;
  } else {
    stories.push(storyWithMetadata);
  }

  // Save back to storage
  localStorage.setItem(STORIES_STORAGE_KEY, JSON.stringify(stories));
};

/**
 * Retrieves all stories from local storage
 * @returns {Array} Array of stories
 */
export const getStoriesFromStorage = () => {
  return JSON.parse(localStorage.getItem(STORIES_STORAGE_KEY)) || [];
};

/**
 * Loads the last created/updated story matching the NSFW filter
 * @param {boolean} isNsfw - Whether to load NSFW stories
 * @returns {Object|null} The last story or null if none exists
 */
export const loadLastStory = (isNsfw) => {
  const stories = getStoriesFromStorage();
  const filteredStories = stories.filter((story) => story.isNsfw === isNsfw);

  return filteredStories.length > 0
    ? filteredStories[filteredStories.length - 1]
    : null;
};

/**
 * Loads a story by its ID (generated from title)
 * @param {string} id - The story ID
 * @returns {Object|null} The story or null if not found
 */
export const loadStoryById = (id) => {
  const stories = getStoriesFromStorage();
  return stories.find((story) => story.id === id) || null;
};

/**
 * Loads a story by its exact title
 * @param {string} title - The story title
 * @returns {Object|null} The story or null if not found
 */
export const loadStoryByTitle = (title) => {
  const stories = getStoriesFromStorage();
  return stories.find((story) => story.title === title) || null;
};

/**
 * Loads all stories matching the NSFW filter
 * @param {boolean} isNsfw - Whether to load NSFW stories
 * @returns {Array} Array of filtered stories
 */
export const getAllStories = (isNsfw) => {
  const stories = getStoriesFromStorage();
  return stories.filter((story) => story.isNsfw === isNsfw);
};

/**
 * Deletes a story from local storage
 * @param {string} storyId - The ID of the story to delete
 * @param {boolean} isNsfw - NSFW filter to ensure correct story deletion
 * @returns {boolean} Whether the deletion was successful
 */
export const deleteStory = (storyId, isNsfw) => {
  const stories = getStoriesFromStorage();
  const storyToDelete = stories.find((story) => story.id === storyId);

  if (!storyToDelete || storyToDelete.isNsfw !== isNsfw) {
    return false;
  }

  const updatedStories = stories.filter((story) => story.id !== storyId);
  localStorage.setItem(STORIES_STORAGE_KEY, JSON.stringify(updatedStories));
  return true;
};

/**
 * Clears all stories from local storage
 */
export const clearAllStories = () => {
  localStorage.removeItem(STORIES_STORAGE_KEY);
};

/**
 * Loads a story into the application state
 * @param {Object} storyData - The story data to load
 * @param {Function} dispatch - The dispatch function from useStory context
 * @returns {boolean} Whether the story was successfully loaded
 */
export const loadStoryIntoState = (storyData, dispatch) => {
  if (!storyData?.paragraphs) {
    return false;
  }

  // Process images to ensure they have the correct format
  let processedImages = [];
  if (Array.isArray(storyData.images)) {
    processedImages = storyData.images.map((image) => {
      // Ensure each image has the required properties
      return {
        id: image.id || Date.now().toString(),
        paragraphIndex: image.paragraphIndex || 0,
        imageId: image.imageId || null,
        imageUrl: image.imageUrl || "",
        caption: image.caption || "",
      };
    });
  }

  // Set images first to ensure they're loaded before other operations
  dispatch({
    type: "SET_IMAGES",
    payload: processedImages,
  });

  // Dispatch all story data to state
  const actions = [
    {
      type: "SET_TOKEN_USAGE",
      payload: storyData.tokenUsage || {
        completion_tokens: 0,
        prompt_tokens: 0,
        total_tokens: 0,
      },
    },
    { type: "SET_TITLE", payload: storyData.title },
    { type: "SET_PREMISE", payload: storyData.premise },
    { type: "SET_GENRE", payload: storyData.genre },
    { type: "SET_PARAGRAPHS", payload: storyData.paragraphs },
    { type: "SET_PICKED_SENTENCES", payload: storyData.pickedSentences || {} },
    { type: "SET_NSFW", payload: storyData.isNsfw || false },
    { type: "SET_OVERALL_SUMMARY", payload: storyData.overall_summary },
    {
      type: "SET_GENERATION_MODE",
      payload: storyData.generationMode || "open_ended",
    },
  ];

  // Set story progress if available
  if (storyData.storyProgress) {
    actions.push({
      type: "UPDATE_STORY_PROGRESS",
      payload: { percentage: storyData.storyProgress },
    });
  }

  // Apply all actions to state
  actions.forEach((action) => dispatch(action));
  return true;
};

/**
 * Creates a new story with a random premise
 * @param {Object} params - Parameters for story initialization
 * @returns {Object} The new story initial data
 */
export const createNewStory = ({ isNsfw, dispatch }) => {
  const premiseData = getRandomPremise(isNsfw);

  dispatch({ type: "RESET_STORY" });
  dispatch({ type: "SET_PREMISE", payload: premiseData.premise });
  dispatch({ type: "SET_GENRE", payload: premiseData.genre });
  dispatch({ type: "SET_NSFW", payload: isNsfw });
  dispatch({ type: "SET_IMAGES", payload: [] });

  return {
    premise: premiseData.premise,
    genre: premiseData.genre,
    isNsfw,
  };
};

/**
 * Attempts to generate a title for the current story
 * @param {Object} state - Current story state
 * @param {Function} dispatch - Dispatch function for updating state
 * @param {Function} setTitle - Function to update title in component state
 * @returns {Promise<string>} The generated title
 */
export const generateStoryTitle = async (state, dispatch, setTitle) => {
  try {
    const context = {
      paragraphs: state.paragraphs.slice(0, 2).join(" "),
      premise: state.premise,
    };

    const generatedTitle = await generateTitle(context);

    if (generatedTitle) {
      dispatch({ type: "SET_TITLE", payload: generatedTitle });
      if (setTitle) setTitle(generatedTitle);
      return generatedTitle;
    }

    return null;
  } catch (error) {
    console.error("Error generating title:", error);
    return null;
  }
};

/**
 * Main function for handling story loading based on different scenarios
 * @param {Object} options - Loading options
 * @returns {Promise<boolean>} Whether a story was loaded successfully
 */
export const handleStoryLoading = async ({
  locationState,
  searchParams,
  dispatch,
  isNsfw,
  onNewStory,
  onScrollToBottom,
  history,
}) => {
  // Case 1: Creating a new story was explicitly requested
  if (searchParams.get("new") === "true") {
    onNewStory();
    return false;
  }

  // Case 2: Loading from story ID (from saved stories)
  if (locationState?.story_id) {
    console.log("locationState", locationState);
    const loadResult = await loadStoryFromIdOrServer(
      locationState.story_id,
      locationState.from_saved,
      dispatch,
      onNewStory
    );

    // Clear the location state after loading the story to prevent reloading on page refresh
    if (history) {
      history.replace({ ...history.location, state: null });
    }

    return loadResult;
  }

  // Case 3: Setting premise from location state
  if (locationState?.premise) {
    dispatch({ type: "SET_PREMISE", payload: locationState.premise });
    dispatch({ type: "SET_GENRE", payload: locationState.genre || "" });
    dispatch({ type: "SET_NSFW", payload: isNsfw });
    dispatch({ type: "SET_IMAGES", payload: [] });

    // Clear the location state after setting premise
    if (history) {
      history.replace({ ...history.location, state: null });
    }

    return true;
  }

  // Case 4: Coming from slim version with generated content
  if (locationState?.fromSlimVersion) {
    if (locationState.premise) {
      // Use the premise if available
      dispatch({ type: "SET_PREMISE", payload: locationState.premise });
      dispatch({ type: "SET_GENRE", payload: locationState.genre || "" });
      dispatch({ type: "SET_NSFW", payload: isNsfw });
      dispatch({ type: "SET_IMAGES", payload: [] });
    } else {
      // Otherwise load last story
      const lastStory = loadLastStory(isNsfw);
      if (!loadStoryIntoState(lastStory, dispatch)) {
        onNewStory();
        return false;
      }
    }

    if (onScrollToBottom) onScrollToBottom();

    // Clear the location state after loading from slim version
    if (history) {
      history.replace({ ...history.location, state: null });
    }

    return true;
  }

  // Case 5: Default - load last story or generate new premise
  const lastStory = loadLastStory(isNsfw);
  if (lastStory) {
    return loadStoryIntoState(lastStory, dispatch);
  } else {
    onNewStory();
    return false;
  }
};

/**
 * Helper function to load a story from ID with fallback to server
 * @param {string} storyId - The ID of the story to load
 * @param {boolean} tryServer - Whether to try loading from server if not in localStorage
 * @param {Function} dispatch - Dispatch function for state updates
 * @param {Function} onNewStory - Function to call if story can't be loaded
 * @returns {Promise<boolean>} Whether a story was loaded
 */
async function loadStoryFromIdOrServer(
  storyId,
  tryServer,
  dispatch,
  onNewStory
) {
  // First try to load from local storage
  const localStory = loadStoryById(storyId);
  if (localStory) {
    return loadStoryIntoState(localStory, dispatch);
  }

  // If not found locally and tryServer is true, attempt to load from server
  if (tryServer) {
    try {
      const serverStory = await getSavedStoryById(storyId);
      if (serverStory) {
        return loadStoryIntoState(serverStory, dispatch);
      }
    } catch (error) {
      console.error("Error loading story from server:", error);
    }
  }

  // If we get here, we couldn't load the story
  if (onNewStory) onNewStory();
  return false;
}
