import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { organizeStories } from "../components/StoryDashboard";
import { getPublishedStories } from "../api/stories";

const PremisesPage = ({ isNsfw = false }) => {
  const [organizedStories, setOrganizedStories] = useState({ byPremise: [] });
  const [error, setError] = useState(null);
  const [expandedPremises, setExpandedPremises] = useState(new Set());
  const history = useHistory();
  const location = useLocation();
  const darkMode = isNsfw ? true : false;

  useEffect(() => {
    const fetchStories = async () => {
      try {
        const response = await getPublishedStories(isNsfw);
        const organized = organizeStories(response.stories);
        setOrganizedStories(organized);
        setError(null);
      } catch (error) {
        console.error("Error fetching stories:", error);
        setError("Failed to load stories. Please try again.");
      }
    };

    fetchStories();
  }, [isNsfw]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const togglePremiseExpansion = (premiseId) => {
    setExpandedPremises((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(premiseId)) {
        newSet.delete(premiseId);
      } else {
        newSet.add(premiseId);
      }
      return newSet;
    });
  };

  return (
    <div
      className={`min-h-screen w-full flex flex-col ${
        darkMode ? "text-gray-100 bg-gray-900" : "text-gray-800 bg-gray-100"
      }`}
    >
      <div className={`flex-1 container mx-auto px-4 py-8`}>
        <div className="flex items-center mb-8">
          <button
            onClick={() => history.goBack()}
            className={`mr-4 px-4 py-2 rounded-lg ${
              darkMode
                ? "bg-gray-800 hover:bg-gray-700 text-white"
                : "bg-gray-200 hover:bg-gray-300 text-gray-900"
            } transition-colors`}
          >
            ← Back
          </button>
          <h1
            className={`text-3xl font-bold ${
              darkMode ? "text-white" : "text-gray-900"
            }`}
          >
            All Premises
          </h1>
        </div>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
          </div>
        )}

        <div className="space-y-8">
          {organizedStories.byPremise.map((premiseGroup) => (
            <div
              key={premiseGroup.id}
              className={`rounded-lg ${
                darkMode ? "bg-gray-800/50" : "bg-gray-50"
              } p-6`}
            >
              <h2
                onClick={() => togglePremiseExpansion(premiseGroup.id)}
                className={`text-xl font-semibold mb-4 cursor-pointer ${
                  darkMode ? "text-white" : "text-gray-900"
                } ${
                  !expandedPremises.has(premiseGroup.id) ? "line-clamp-2" : ""
                }`}
              >
                {premiseGroup.premise}
              </h2>

              <div className="overflow-x-auto">
                <table className="w-full">
                  <tbody>
                    {premiseGroup.stories.map((story) => (
                      <tr
                        key={story.id}
                        className={`border-b ${
                          darkMode
                            ? "border-gray-700 hover:bg-gray-700/50"
                            : "border-gray-200 hover:bg-gray-100"
                        } transition-colors`}
                      >
                        <td className="py-3 px-4">
                          <Link
                            to={`/story/${story.id}`}
                            className={`font-medium hover:text-blue-400 transition-colors`}
                          >
                            {story.title}
                          </Link>
                        </td>
                        <td className="py-3 px-4 hidden sm:table-cell">
                          {story.author}
                        </td>
                        <td className="py-3 px-4 text-right hidden md:table-cell">
                          {story.createdAt ? formatDate(story.createdAt) : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PremisesPage;
