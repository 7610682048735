import { apiCall } from "../utils/api_utils";

const API_ENDPOINTS = {
  BALANCE: "api/v1/wallet/balance",
  ADD_FUNDS: "api/v1/wallet/add-funds",
  CONFIRM_PAYMENT: "api/v1/wallet/confirm-payment",
  TRANSACTIONS: "api/v1/wallet/transactions",
};

export const getWalletBalance = async () => {
  try {
    const response = await apiCall(API_ENDPOINTS.BALANCE, "GET");
    return response.balance;
  } catch (error) {
    console.error("Error fetching wallet balance:", error);
    throw error;
  }
};

export const getTransactions = async () => {
  try {
    const response = await apiCall(API_ENDPOINTS.TRANSACTIONS, "GET");
    return response.transactions;
  } catch (error) {
    console.error("Error fetching transactions:", error);
    throw error;
  }
};

export const addFunds = async (amount) => {
  try {
    const response = await apiCall(API_ENDPOINTS.ADD_FUNDS, "POST", { amount });
    return {
      clientSecret: response.clientSecret,
      paymentIntentId: response.paymentIntentId,
      amount: response.amount,
    };
  } catch (error) {
    console.error("Error adding funds:", error);
    throw error;
  }
};

export const confirmPayment = async (paymentIntentId, amount) => {
  try {
    const response = await apiCall(API_ENDPOINTS.CONFIRM_PAYMENT, "POST", {
      paymentIntentId,
      amount,
    });
    return response;
  } catch (error) {
    console.error("Error confirming payment:", error);
    throw error;
  }
};
