import React from "react";
import { FaChartBar, FaChevronUp, FaChevronDown } from "react-icons/fa";

const TokenUsageDisplay = ({
  tokenUsage = {},
  showTokens,
  toggleTokenDisplay,
  darkMode,
}) => {
  const formatNumber = (num) => {
    return new Intl.NumberFormat().format(num);
  };

  const totalTokens = tokenUsage?.total_tokens || 0;
  const promptTokens = tokenUsage?.prompt_tokens || 0;
  const completionTokens = tokenUsage?.completion_tokens || 0;

  const promptPercentage = totalTokens ? (promptTokens / totalTokens) * 100 : 0;
  const completionPercentage = totalTokens
    ? (completionTokens / totalTokens) * 100
    : 0;

  return (
    <div className="relative">
      <button
        onClick={toggleTokenDisplay}
        className={`ml-2 p-2 rounded-full transition-colors duration-200 flex items-center ${
          darkMode
            ? "hover:bg-gray-700 text-gray-300"
            : "hover:bg-gray-100 text-gray-600"
        } ${showTokens ? "bg-gray-100 text-amber-500" : ""}`}
        title="Toggle token usage"
      >
        <FaChartBar className="mr-1" />
        {showTokens ? <FaChevronUp size={12} /> : <FaChevronDown size={12} />}
      </button>

      {showTokens && (
        <div
          className={`absolute right-0 top-full mt-2 p-3 rounded-lg transition-all duration-300 shadow-lg z-10 w-80 ${
            darkMode ? "bg-gray-800" : "bg-gray-100"
          }`}
        >
          <div className="text-sm mb-1 flex justify-between">
            <span
              className={`font-medium ${
                darkMode ? "text-gray-300" : "text-gray-600"
              }`}
            >
              Token Usage
            </span>
            <span
              className={`font-medium ${
                darkMode ? "text-gray-300" : "text-gray-600"
              }`}
            >
              {formatNumber(totalTokens)} total
            </span>
          </div>
          <div className="w-full h-6 bg-gray-200 rounded-full overflow-hidden">
            <div className="h-full flex">
              <div
                style={{
                  width: `${promptPercentage}%`,
                }}
                className="bg-amber-400 flex items-center justify-center text-xs font-medium text-amber-900"
              >
                <span className="hidden sm:inline">
                  {formatNumber(promptTokens)} prompt
                </span>
                <span className="sm:hidden">{formatNumber(promptTokens)}</span>
              </div>
              <div
                style={{
                  width: `${completionPercentage}%`,
                }}
                className="bg-amber-500 flex items-center justify-center text-xs font-medium text-amber-900"
              >
                <span className="hidden sm:inline">
                  {formatNumber(completionTokens)} completion
                </span>
                <span className="sm:hidden">
                  {formatNumber(completionTokens)}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TokenUsageDisplay;
