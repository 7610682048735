import { streamedApiCall, regularApiCall } from "../utils/api_utils";

const API_VER = "api/v1/";
const API_ENDPOINTS = {
  NEW_PARAGRAPHS: API_VER + "paragraphs/new",
  EDIT_PARAGRAPH: API_VER + "paragraphs/edit",
  REWRITE_PARAGRAPHS: API_VER + "paragraphs/rewrite",
  INSERTED_PARAGRAPHS: API_VER + "paragraphs/inserted",
  SECTION_SUMMARY: API_VER + "paragraphs/section/summary",
  PARAGRAPHS_SUGGESTIONS: API_VER + "paragraphs/suggestions",
  IMPROVED_PARAGRAPHS: API_VER + "paragraphs/improved",
};

export const cancelGeneration = async () => {
  try {
    regularApiCall(`${process.env.REACT_APP_API_URL}/${API_VER}cancel`, "POST");
  } catch (error) {
    console.error("Error cancelling generation:", error);
  }
};

export const getNewParagraphs = async (
  instruction,
  count = 1,
  context,
  type = "open_ended",
  onProgress,
  stream = true,
  isNsfw = false,
  onUsage = null,
  onProgressReasoning = null,
  isReasoning = false
) => {
  return new Promise((resolve, reject) => {
    console.log("getNewParagraphs", isNsfw, isReasoning);
    let newParagraphs = [];
    let currentParagraph = "";

    const sendUpdate = () => {
      onProgress([...newParagraphs, currentParagraph.trim()]);
    };

    const onChunk = (data) => {
      if (data.chunk) {
        if (data.chunk === "[DONE]") {
          if (currentParagraph.trim()) {
            newParagraphs.push(currentParagraph.trim());
          }
          resolve(newParagraphs);
        } else if (data.chunk === "[CANCELLED]") {
          reject(new Error("Generation cancelled"));
        } else {
          if (data.chunk.includes("\\n\\n")) {
            let splits = data.chunk.split("\\n\\n");
            currentParagraph += splits[0] + " ";
            newParagraphs.push(currentParagraph.trim());
            currentParagraph = splits[1];
            sendUpdate();
          } else {
            currentParagraph += data.chunk + " ";
            sendUpdate();
          }
        }
      }
    };

    const onError = (error) => {
      reject(error);
    };

    const handleUsage = (usage) => {
      if (onUsage) {
        onUsage(usage);
      }
    };

    const handleReasoningChunk = (reasoning) => {
      // console.log("handleReasoningChunk: ", reasoning);
      if (onProgressReasoning) {
        onProgressReasoning(reasoning);
      }
    };

    streamedApiCall(
      `${process.env.REACT_APP_API_URL}/${API_ENDPOINTS.NEW_PARAGRAPHS}`,
      "POST",
      {
        context: context,
        instruction,
        count,
        type,
        isNsfw,
        stream,
        isReasoning,
      },
      onChunk,
      onError,
      handleUsage,
      handleReasoningChunk
    );
  });
};

export const getEditedParagraph = async (sentencesWithActions, onProgress) => {
  return new Promise((resolve, reject) => {
    let newSentences = [];

    const onChunk = (data) => {
      if (data.chunk) {
        if (data.chunk === "[DONE]") {
          resolve(newSentences);
        } else {
          let parsedChunk = JSON.parse(data.chunk);
          console.log(parsedChunk);
          newSentences = parsedChunk;
        }
      }
    };

    const onError = (error) => {
      reject(error);
    };

    streamedApiCall(
      `${process.env.REACT_APP_API_URL}/${API_ENDPOINTS.EDIT_PARAGRAPH}`,
      "POST",
      {
        context: {
          synopsis:
            "Frustrated by countless rejections, Arjun embarks on an online job search journey that forces him to confront his insecurities and aspirations.",
        },
        sentencesWithActions,
        isNsfw: false,
        stream: true,
      },
      onChunk,
      onError
    );
  });
};

const cleanJsonString = (jsonString) => {
  jsonString = jsonString.replace(/^```json\s*/m, "");
  jsonString = jsonString.replace(/\s*```$/m, "");
  return jsonString.trim();
};

export const getRewriteParagraphs = async (
  instruction,
  count = 1,
  context,
  onProgress,
  stream = true,
  isNsfw = true,
  onUsage = null,
  onProgressReasoning = null,
  isReasoning = false
) => {
  return new Promise((resolve, reject) => {
    console.log("getRewriteParagraphs");
    let newParagraphs = [{ sentences: [] }];
    let currentIndex = 0;

    const onChunk = (data) => {
      if (data.chunk) {
        if (data.chunk === "[DONE]") {
          //if the last paragraph is empty, remove it
          if (newParagraphs[currentIndex].sentences.length === 0) {
            newParagraphs.pop();
          }
          resolve(newParagraphs);
        } else if (data.chunk === "[CANCELLED]") {
          reject(new Error("Generation cancelled"));
        } else {
          const cleanedChunk = cleanJsonString(data.chunk);
          let parsedChunk = JSON.parse(cleanedChunk);
          if (parsedChunk.action !== "paragraph_break") {
            newParagraphs[currentIndex].sentences.push(parsedChunk);
            onProgress(newParagraphs);
          } else {
            currentIndex++;
            newParagraphs.push({ sentences: [] });
          }
        }
      }
    };

    const onError = (error) => {
      reject(error);
    };

    const handleUsage = (usage) => {
      if (onUsage) {
        onUsage(usage);
      }
    };

    const handleReasoningChunk = (reasoning) => {
      // console.log("handleReasoningChunk: ", reasoning);
      onProgressReasoning(reasoning);
    };

    streamedApiCall(
      `${process.env.REACT_APP_API_URL}/${API_ENDPOINTS.REWRITE_PARAGRAPHS}`,

      "POST",
      {
        context: context,
        instruction,
        count,
        isNsfw: isNsfw,
        stream: true,
        isReasoning,
      },
      onChunk,
      onError,
      handleUsage,
      handleReasoningChunk
    );
  });
};

export const getInsertedParagraphs = async (
  instruction,
  count = 1,
  context,
  onProgress,
  stream = true,
  isNsfw = false,
  generation_type = "open_ended",
  onUsage = null,
  onProgressReasoning = null,
  isReasoning = false
) => {
  return new Promise((resolve, reject) => {
    let newParagraphs = [];
    let currentParagraph = "";

    const sendUpdate = () => {
      onProgress([...newParagraphs, currentParagraph.trim()]);
    };

    const onChunk = (data) => {
      if (data.chunk) {
        if (data.chunk === "[DONE]") {
          resolve(newParagraphs);
        } else if (data.chunk === "[CANCELLED]") {
          reject(new Error("Generation cancelled"));
        } else {
          if (data.chunk.includes("\\n\\n")) {
            let splits = data.chunk.split("\\n\\n");
            currentParagraph += splits[0] + " ";
            newParagraphs.push(currentParagraph.trim());
            currentParagraph = splits[1];
            sendUpdate();
          } else {
            currentParagraph += data.chunk + " ";
            sendUpdate();
          }
        }
      }
    };

    const handleReasoningChunk = (reasoning) => {
      // console.log("handleReasoningChunk: ", reasoning);
      onProgressReasoning(reasoning);
    };

    const onError = (error) => {
      reject(error);
    };

    const handleUsage = (usage) => {
      if (onUsage) {
        onUsage(usage);
      }
    };

    console.log("getInsertedParagraphs", generation_type);

    streamedApiCall(
      `${process.env.REACT_APP_API_URL}/${API_ENDPOINTS.INSERTED_PARAGRAPHS}`,
      "POST",
      {
        context,
        instruction,
        count,
        isNsfw,
        stream,
        generation_type,
        isReasoning,
      },
      onChunk,
      onError,
      handleUsage,
      handleReasoningChunk
    );
  });
};

export const getSectionSummary = (paragraphs, prev_sections, nsfw = true) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await regularApiCall(
        `${process.env.REACT_APP_API_URL}/${API_ENDPOINTS.SECTION_SUMMARY}`,
        "POST",
        {
          paragraphs,
          prev_sections,
          stream: false,
          isNsfw: nsfw,
        }
      );
      if (response.error) {
        reject(response.error);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const getSuggestions = async (context, nsfw = true) => {
  try {
    const response = await regularApiCall(
      `${process.env.REACT_APP_API_URL}/${API_ENDPOINTS.PARAGRAPHS_SUGGESTIONS}`,
      "POST",
      {
        context,
        count: 3,
        isNsfw: nsfw,
      }
    );
    return response.suggestions;
  } catch (error) {
    console.error("Error fetching suggestions:", error);
    throw error;
  }
};

export const getImprovedParagraphs = async (
  instruction,
  paragraphs,
  count = 1,
  context,
  onProgress,
  stream = true,
  isNsfw = false,
  onUsage = null
) => {
  return new Promise((resolve, reject) => {
    let newParagraphs = [];
    let currentParagraph = "";

    const sendUpdate = () => {
      onProgress([...newParagraphs, currentParagraph.trim()]);
    };

    const onChunk = (data) => {
      if (data.chunk) {
        if (data.chunk === "[DONE]") {
          if (currentParagraph.trim()) {
            newParagraphs.push(currentParagraph.trim());
          }
          resolve(newParagraphs);
        } else if (data.chunk === "[CANCELLED]") {
          reject(new Error("Generation cancelled"));
        } else {
          if (data.chunk.includes("\\n\\n")) {
            let splits = data.chunk.split("\\n\\n");
            currentParagraph += splits[0] + " ";
            newParagraphs.push(currentParagraph.trim());
            currentParagraph = splits[1];
            sendUpdate();
          } else {
            currentParagraph += data.chunk + " ";
            sendUpdate();
          }
        }
      }
    };

    const onError = (error) => {
      reject(error);
    };

    const handleUsage = (usage) => {
      if (onUsage) {
        onUsage(usage);
      }
    };

    streamedApiCall(
      `${process.env.REACT_APP_API_URL}/${API_ENDPOINTS.IMPROVED_PARAGRAPHS}`,
      "POST",
      {
        context,
        instruction,
        paragraphs,
        count,
        isNsfw,
        stream,
      },
      onChunk,
      onError,
      handleUsage
    );
  });
};
