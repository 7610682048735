import yaml from "js-yaml";
export const streamedApiCallBasic = async (
  url,
  method,
  body,
  onChunk,
  onError
) => {
  try {
    const response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      const chunk = decoder.decode(value);
      onChunk(chunk);
    }
  } catch (error) {
    console.error("Error in API call:", error);
    onError(error);
  }
};

export const streamedApiCall = async (
  url,
  method,
  body,
  onChunk,
  onError,
  onUsage,
  onReasoningChunk
) => {
  const fetchOptions = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(body),
  };

  try {
    const response = await fetch(url, fetchOptions);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader.read();

      if (done) break;
      const chunk = decoder.decode(value);
      const lines = chunk.split("\n");

      for (const line of lines) {
        if (!line.trim()) continue;
        let data;
        try {
          data = JSON.parse(line);
        } catch (parseError) {
          onError(
            new Error("Error parsing server response: " + parseError.message)
          );
          return;
        }

        if (data.error) {
          console.error("Error in API call:", data.error);
          onError(new Error(data.error));
          return;
        }

        if (data.chunk && data.chunk.startsWith("I'm sorry")) {
          onError(new Error(data.chunk));
          return;
        }

        onChunk(data);

        if (data.usage) {
          onUsage(data.usage);
        }

        if (data.reasoning) {
          // console.log("Reasoning chunk:", data.reasoning);
          onReasoningChunk(data.reasoning);
        }
      }
    }
  } catch (error) {
    console.error("Error in API call:", error);
    onError(error);
  }
};

export const regularApiCall = async (url, method, body) => {
  try {
    // Check token expiration before making API call
    const tokenExpiry = localStorage.getItem("tokenExpiry");
    const token = localStorage.getItem("token");

    if (!token) {
      // If no token exists, redirect to login
      localStorage.removeItem("token");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("tokenExpiry");
      window.location.href = "/login";
      throw new Error("No token found");
    }

    const currentTime = new Date().getTime();

    // Make the API call
    const response = await fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      // If token has expired (401 response), redirect to login
      if (response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("tokenExpiry");
        window.location.href = "/login";
        throw new Error("Token expired");
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    if (data.error) {
      return { error: data.error };
    }

    return data;
  } catch (error) {
    console.error("Error in API call:", error);
    return { error: "An error occurred while fetching data" };
  }
};

export const apiCall = async (endpoint, method = "GET", body = null) => {
  const url = `${process.env.REACT_APP_API_URL}/${endpoint}`;
  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Fetch the response as text (YAML)
    const textResponse = await response.text();

    // Parse the YAML response to JavaScript object
    const data = yaml.load(textResponse);

    return data;
  } catch (error) {
    console.error("Error in API call:", error);
    throw error;
  }
};
