import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { formatDate } from "../utils/dateUtils";
import { useHistory } from "react-router-dom";
import {
  getWalletBalance,
  getTransactions,
  addFunds,
  confirmPayment,
} from "../api/wallet";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "16px",
      color: "#1F2937",
      "::placeholder": {
        color: "#6B7280",
      },
    },
    invalid: {
      color: "#DC2626",
    },
  },
};

function UserProfilePage() {
  const { user, token, loading, logout } = useAuth();
  const stripe = useStripe();
  const elements = useElements();
  const [tab, setTab] = useState("profile");
  const [balance, setBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [localLoading, setLocalLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!user || !token) {
      history.push("/login");
      return;
    }
    fetchWalletData();
  }, [token, user, history, loading]);

  const fetchWalletData = async () => {
    try {
      const balance = await getWalletBalance();
      setBalance(balance);

      const transactionsData = await getTransactions();
      setTransactions(transactionsData);
    } catch (err) {
      setError("Failed to fetch wallet data");
    }
  };

  const handleAddFunds = async (e) => {
    e.preventDefault();
    setLocalLoading(true);
    setError("");
    setSuccess("");

    try {
      const intentData = await addFunds(parseFloat(amount));

      const { error: stripeError } = await stripe.confirmCardPayment(
        intentData.clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        }
      );

      if (stripeError) {
        throw new Error(stripeError.message);
      }

      const confirmData = await confirmPayment(
        intentData.paymentIntentId,
        parseFloat(amount)
      );

      if (confirmData.error) {
        throw new Error(confirmData.error);
      }

      setSuccess("Funds added successfully!");
      setAmount("");
      elements.getElement(CardElement).clear();
      fetchWalletData();
    } catch (err) {
      setError(err.message);
    } finally {
      setLocalLoading(false);
    }
  };

  const handleLogout = () => {
    logout();
    history.push("/login");
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h1 className="text-3xl font-bold mb-6">User Profile</h1>

        {/* Tabs */}
        <div className="border-b border-gray-200 mb-6">
          <nav className="flex space-x-8">
            {["profile", "wallet", "transactions"].map((tabName) => (
              <button
                key={tabName}
                onClick={() => setTab(tabName)}
                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                  tab === tabName
                    ? "border-blue-500 text-blue-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }`}
              >
                {tabName.charAt(0).toUpperCase() + tabName.slice(1)}
              </button>
            ))}
          </nav>
        </div>

        {/* Profile Tab */}
        {tab === "profile" && (
          <div className="space-y-4">
            <div className="flex justify-between items-center mb-6">
              <div className="space-y-4">
                <div>
                  <h2 className="text-xl font-semibold mb-2">Email</h2>
                  <p className="text-gray-600">{user?.email}</p>
                </div>
                <div>
                  <h2 className="text-xl font-semibold mb-2">Member Since</h2>
                  <p className="text-gray-600">
                    {formatDate(user?.created_at)}
                  </p>
                </div>
              </div>
              <button
                onClick={handleLogout}
                className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-md transition-colors duration-200 flex items-center gap-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 3a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3zm11 4.414l-4.293 4.293a1 1 0 0 1-1.414-1.414L11.586 7H6a1 1 0 1 1 0-2h5.586L8.293 1.707a1 1 0 0 1 1.414-1.414L14 4.586v2.828z"
                    clipRule="evenodd"
                  />
                </svg>
                Logout
              </button>
            </div>
          </div>
        )}

        {/* Wallet Tab */}
        {tab === "wallet" && (
          <div>
            <div className="mb-6">
              <h2 className="text-xl font-semibold mb-2">Current Balance</h2>
              <p className="text-3xl font-bold text-green-600">
                ${balance.toFixed(2)}
              </p>
            </div>

            <form onSubmit={handleAddFunds} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Amount to Add ($)
                </label>
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  required
                  min="0"
                  step="0.01"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Card Details
                </label>
                <div className="border border-gray-300 rounded-md p-3">
                  <CardElement options={CARD_ELEMENT_OPTIONS} />
                </div>
              </div>

              <button
                type="submit"
                disabled={localLoading || !stripe}
                className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
                  ${
                    localLoading
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  }`}
              >
                {localLoading ? (
                  <span className="flex items-center justify-center">
                    <svg
                      className="animate-spin h-5 w-5 mr-3"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                        fill="none"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    Processing...
                  </span>
                ) : (
                  "Add Funds"
                )}
              </button>
            </form>

            {error && (
              <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-md">
                <p className="text-red-600">{error}</p>
              </div>
            )}
            {success && (
              <div className="mt-4 p-4 bg-green-50 border border-green-200 rounded-md">
                <p className="text-green-600">{success}</p>
              </div>
            )}
          </div>
        )}

        {/* Transactions Tab */}
        {tab === "transactions" && (
          <div className="space-y-4">
            {transactions.map((transaction, index) => (
              <div
                key={index}
                className="border rounded-lg p-4 hover:shadow-md transition-shadow"
              >
                <div className="flex justify-between items-start">
                  <div>
                    <p className="font-medium">
                      {transaction.type === "deposit" ? "Added Funds" : "Spent"}
                    </p>
                    <p className="text-sm text-gray-500">
                      {formatDate(transaction.timestamp)}
                    </p>
                    {transaction.description && (
                      <p className="text-sm text-gray-600 mt-1">
                        {transaction.description}
                      </p>
                    )}
                  </div>
                  <div className="text-right">
                    <p
                      className={`text-lg font-semibold ${
                        transaction.type === "deposit"
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                    >
                      {transaction.type === "deposit" ? "+" : "-"}$
                      {transaction.amount.toFixed(2)}
                    </p>
                    <p className="text-sm text-gray-500">
                      Balance: ${transaction.balance_after.toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            ))}
            {transactions.length === 0 && (
              <p className="text-gray-500 text-center py-4">
                No transactions yet
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default UserProfilePage;
