import React from "react";
import { Helmet } from "react-helmet";
import LandingPage from "./views/LandingPage";
import StoryGeneratorFull from "./views/StoryGeneratorFull";
import StoryGeneratorCompact from "./views/StoryGeneratorCompact";
import AISceneGenerator from "./views/AISceneGenerator";
import StoriesPage from "./views/StoriesPage";
import AdminPage from "./views/AdminPage";
import APITest from "./views/APITest";
import { StoryProvider } from "./context/StoryContext";
import { SceneProvider } from "./context/SceneContext";
import PromptEditor from "./components/PromptEditor";
import MainLayout from "./MainLayout";
import PublishedStoryView from "./views/PublishedStoryView";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import WordByWordDemo from "./views/WordByWordDemo";
import { useState } from "react";
import SceneWordByWordDemo from "./views/SceneWordByWordDemo";
import NSFWLandingPage from "./views/NSFWLandingPage";
import PremisesPage from "./views/PremisesPage";
import VoiceTest from "./views/VoiceTest";
import ScenesPage from "./views/ScenesPage";
import UsageReportView from "./views/UsageReportView";
import { AuthProvider } from "./context/AuthContext";
import LoginPage from "./views/LoginPage";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutPage from "./views/CheckoutPage";
import UserProfilePage from "./views/UserProfilePage";
import RegisterPage from "./views/RegisterPage";
import ParagraphView from "./views/ParagraphView";
// Initialize Stripe Promise - add this before the App function
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  const [darkMode, setDarkMode] = useState(false);

  return (
    <AuthProvider>
      <StoryProvider>
        <SceneProvider>
          <Elements stripe={stripePromise}>
            <Router>
              <Helmet>
                <html lang="en" />
                <meta charSet="utf-8" />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1"
                />
                <meta name="theme-color" content="#000000" />
              </Helmet>
              <MainLayout>
                <Switch>
                  <Route path="/" exact>
                    <LandingPage />
                  </Route>
                  <Route path="/ai-story-generator" exact>
                    <StoryGeneratorFull darkMode={darkMode} />
                  </Route>
                  <Route path="/ai-story-generator/compact" exact>
                    <StoryGeneratorCompact darkMode={false} />
                  </Route>
                  <Route path="/ai-story-generator/nsfw" exact>
                    <StoryGeneratorFull darkMode={true} isNsfw={true} />
                  </Route>
                  <Route path="/ai-story-generator/nsfw/compact" exact>
                    <StoryGeneratorCompact darkMode={true} isNsfw={true} />
                  </Route>
                  <Route path="/ai-scene-generator" exact>
                    <AISceneGenerator darkMode={false} />
                  </Route>
                  <Route path="/ai-scene-generator/nsfw">
                    <AISceneGenerator darkMode={true} isNsfw={true} />
                  </Route>
                  <Route path="/stories" exact>
                    <StoriesPage darkMode={darkMode} />
                  </Route>
                  <Route path="/stories/nsfw">
                    <StoriesPage darkMode={true} isNsfw={true} />
                  </Route>
                  <Route path="/scenes" exact>
                    <ScenesPage darkMode={darkMode} />
                  </Route>
                  <Route path="/scenes/nsfw">
                    <ScenesPage darkMode={true} isNsfw={true} />
                  </Route>
                  <Route path="/admin" exact component={AdminPage} />
                  <Route path="/prompts" exact component={PromptEditor} />
                  <Route path="/testapi" exact component={APITest} />
                  <Route path="/word-demo" component={WordByWordDemo} />
                  <Route path="/story/:id" component={PublishedStoryView} />
                  <Route
                    path="/scene-word-demo"
                    component={SceneWordByWordDemo}
                  />
                  <Route exact path="/nsfw" component={NSFWLandingPage} />
                  <Route path="/premises" exact>
                    <PremisesPage />
                  </Route>
                  <Route path="/premises/nsfw">
                    <PremisesPage isNsfw={true} />
                  </Route>
                  <Route path="/voice/test" component={VoiceTest} />
                  <Route path="/usage" exact>
                    <UsageReportView />
                  </Route>
                  <Route path="/paragraph-view" component={ParagraphView} />
                  <Route path="/checkout" component={CheckoutPage} />
                  <Route path="/login" component={LoginPage} />
                  <Route path="/register" component={RegisterPage} />
                  <Route path="/profile" component={UserProfilePage} />
                </Switch>
              </MainLayout>
            </Router>
          </Elements>
        </SceneProvider>
      </StoryProvider>
    </AuthProvider>
  );
}

export default App;
